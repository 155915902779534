import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { getTestRun, getTestRuns, postTestRuns } from "../api/testRuns";
import { RootState } from "../reducers/rootReducer";
import { setAddTestRun, setTestRunUpdated, testRunslice } from "../reducers/testRunsReducer";
import { checkAuthExpired } from "./authActions";
import { ITestRun, TestRun } from "../models/testRun";

export const testRunsActions = testRunslice.actions

export const loadTestRuns=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return getTestRuns(getState().auth.accessToken || "", getState().testRuns.page, getState().testRuns.perPage).then( response => {
            dispatch(testRunsActions.setLoadSuccess(response))
        }).catch(err => {
            dispatch(testRunsActions.setLoadFailed({message: "Failed to get test suites:"+err}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const startTestRun=(testSuiteId: number): ThunkAction<Promise<number | undefined>, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return postTestRuns(getState().auth.accessToken || "", testSuiteId).then( (response: ITestRun) => {
            return response.id
        }).catch(err => {
            dispatch(checkAuthExpired(err))
            throw(err.response.data.error)
        })
    }
}

export const testRunCreated=(testRun: ITestRun): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        dispatch(setAddTestRun(testRun))
    }
}

export const testRunUpdated=(testRun: ITestRun): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        dispatch(setTestRunUpdated(testRun))
    }
}



export const updateTestRunsPage=(page: number): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        dispatch(testRunsActions.setCurrentPage(page))
        dispatch(loadTestRuns())
    }
}