import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { getDevices, postDevice, putDevice } from "../api/devices";
import { Device } from "../reducers/devicesReducer";
import { RootState } from "../reducers/rootReducer";
import { devicesSlice } from "../reducers/devicesReducer";
import { checkAuthExpired } from "./authActions";
import { deleteAccount, getUsers } from "../api/users";
import { usersSlice } from "../reducers/usersReducer";

export const userActions = usersSlice.actions

export const loadUsers=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return getUsers(getState().auth.accessToken || "").then( response => {
            dispatch(userActions.setLoadSuccess(response))
        }).catch(err => {
            dispatch(userActions.setLoadFailed({message: "Failed to get users:"+err}))
            dispatch(checkAuthExpired(err))
        })
    }
}


export const updateUser=(device: Device): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        dispatch(userActions.setIsUpdating(true))
        return putDevice(getState().auth.accessToken || "", device).then( response => {
            dispatch(userActions.setUpdateSuccess(response))
            dispatch(loadUsers())
        }).catch(err => {
            dispatch(userActions.setUpdateFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const createUser=(device: Device): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        dispatch(userActions.setIsUpdating(true))
        return postDevice(getState().auth.accessToken || "", device).then( response => {
            dispatch(userActions.setUpdateSuccess(response))
            dispatch(loadUsers())
        }).catch(err => {
            dispatch(userActions.setUpdateFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const deleteUser=(id: number): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return deleteAccount(getState().auth.accessToken || "", id).then( response => {
            dispatch(loadUsers())
        }).catch(err => {
            dispatch(userActions.setDeleteFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const cancelUserEditing=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        return dispatch(userActions.setIsEditing(false))
    }
}

export const setUserEditing=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        return dispatch(userActions.setIsEditing(true))
    }
}

export const clearError=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        return dispatch(userActions.setClearError())
    }
}