import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Device } from './devicesReducer'

export interface Error {
    message: string
}

export enum Platform{
    Unknown = "Unknown",
    Web = "Web",
    Android = "Android",
    iOS = "iOS",
    Windows = "Windows",
}

export enum Roles {
    Device = "Device",
    Viewer = "Viewer",
    Manager = "Manager",
    Admin = "Admin" 
}

export interface User extends Device{
    blacklist: boolean
}

export interface UsersState{
    users: User[]
    isLoading: boolean
    error: Error | undefined
    editing: {
        isEditing: boolean,
        isUpdating: boolean,
        error: Error
    }
}

export const initialState: UsersState = {
    users: [],
    isLoading: false,
    error: {message: ""},
    editing: {
        isEditing: false,
        isUpdating: false,
        error: {message: ""}
    }
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setLoadSuccess: (state, { payload }: PayloadAction<User[]>) => {
            state.users = payload.filter(u => u.platform === Platform.Web)
            state.error = undefined
            state.isLoading = false
        },
        setLoadFailed: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.users = []
            state.isLoading = false
        },
        setClearError: (state) => {
            state.error = undefined
        },
        setDeleteFailed: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
        setIsEditing: (state, { payload }: PayloadAction<boolean>) => {
            state.editing.isEditing = payload
            state.editing.isUpdating = false
        },
        setIsUpdating: (state, {payload}: PayloadAction<boolean>) => {
            state.editing.isUpdating = true
        },
        setUpdateFailed:  (state, { payload }: PayloadAction<Error>) => {
            state.editing.error = payload
            state.editing.isUpdating = false
        },
        setUpdateSuccess:  (state, { payload }: PayloadAction<Device>) => {
            state.editing.isEditing = false
            state.editing.isUpdating = false
            state.editing.error = {message: ""}
        }
    }
})

export const { setLoadSuccess, setLoading, setLoadFailed, setIsEditing, setIsUpdating, setClearError, setDeleteFailed} = usersSlice.actions
