
import { useAppDispatch } from "../../store/store";
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { addFavourite, addTestSuite, cloneTestSuite, loadTestSuites, removeFavourite, removeTestSuite, setTestSuiteEditing} from "../../actions/testSuiteActions";
import { useAppSelector } from "../../hooks/redux-hooks";
import { AppLayout } from '../../components/layouts/AppLayout';
import { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Hidden, Menu, MenuItem, Paper, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from "@mui/material";
import { selectFilteredTestSuites, SuiteState, TestConfigPopulated } from "../../reducers/testSuitesReducer";
import RunTestIcon from '@mui/icons-material/PlayArrow';
import { startTestRun } from "../../actions/testRunActions";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ScheduleTestIcon from '@mui/icons-material/Schedule';
import { green, red, blue, amber, grey, yellow } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router";
import { IPaginationState } from "../../models/paginationState";
import { TestRunModal } from "../modal/TestRunModal";
import StarIcon from '@mui/icons-material/Star';
import { FavouriteFilter } from "../../components/FavouriteFilter";
import { useSelector } from "react-redux";

const columns = (runCallback: (id: number) => void, editCallback: (id: number) => void, deleteCallback: (id: number) => void, 
    cloneCallback: (id: number) => void, addFavouriteCallback: (id: number) => void, removeFavouriteCallback: (id: number) => void) => {
    return [
        { field: 'id', headerName: 'ID', flex: 1},
        { field: 'name', headerName: 'Name', flex: 1},
        { field: 'state', headerName: 'Execution method', flex: 1},
        { field: 'scheduleFrequency', headerName: 'Frequency (mins)', flex: 1},
        {
            field: 'tests', headerName: 'Tests', flex: 3,
            _renderCell: (params: GridRenderCellParams) => (
                <Accordion square elevation={0} sx={{width: "100%", backgroundColor: "transparent"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                            <Stack direction="row" spacing={1}>
                                {params.row.tests.map((test: TestConfigPopulated) => <Avatar key={test.id} sx={{  width: 28, height: 28, backgroundColor: {"Pro" : green[200], "Exe": blue[200], "Dep": red[200], "Del": grey[300]}[test.testType.substring(0, 3)] }}>{test.testType.substring(0, 1)}</Avatar>)}
                            </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer sx={{width: '100%'}}>
                            <Table size="small" sx={{width: 10, overflow: "hidden"}}>
                                <TableBody>
                                    {params.row.tests.map((test: TestConfigPopulated, i: number) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell style={{width: '25%'}}>
                                                    {test.testType}
                                                </TableCell>
                                                <TableCell style={{width: '25%'}}>
                                                    {test.device?.username}
                                                </TableCell>
                                                <TableCell style={{width: '25%'}}>
                                                    {test.card?.name}
                                                </TableCell>
                                                <TableCell style={{width: '25%'}}>
                                                    {test.timeoutDuration} secs
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            ),
            get renderCell() {
                return this._renderCell;
            },
            set renderCell(value) {
                this._renderCell = value;
            },
        },
        {
            field: 'actions', headerName: '', width: 110, flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Stack sx={{alignItems: 'center', mt: '12px'}} direction="row" spacing={1} useFlexGap={true}>
                    {params.row.state === SuiteState.Manual && <Tooltip title="Execute a test run"><RunTestIcon sx={{ color: green[500]}} onClick={() => {runCallback(params.row.id)}}/></Tooltip>}
                    {params.row.state === SuiteState.Scheduled && <Tooltip title="Test is currently scheduled"><ScheduleTestIcon sx={{ color: green[500] }}/></Tooltip>}
                    <Tooltip title="Edit the Test Suite"><EditIcon onClick={() => {editCallback(params.row.id)}}/></Tooltip>
                    <Tooltip title="Delete the Test Suite"><DeleteIcon sx={{ color: red[500] }}  onClick={() => {deleteCallback(params.row.id)}}/></Tooltip>
                    <Tooltip title="Clone the Test Suite"><ContentCopyIcon sx={{ color: blue[500] }}  onClick={() => {cloneCallback(params.row.id)}}/></Tooltip>
                    {params.row.favourite && 
                        <Tooltip title="Remove Favourite"><StarIcon sx={{ color: yellow[700] }}  onClick={() => {removeFavouriteCallback(params.row.id)}}/></Tooltip>                    
                    }                    
                    {!params.row.favourite && 
                        <Tooltip title="Favourite the Card"><StarIcon sx={{ color: grey[400] }}  onClick={() => {addFavouriteCallback(params.row.id)}}/></Tooltip>                    
                    }                 
                </Stack>
            )
        }
    ];
}



export const TestSuites = () =>  {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    var testSuites = useSelector(selectFilteredTestSuites)

    var isEditing = useAppSelector(state => state.testSuites.editing.isEditing)
    var testRuns = useAppSelector(state => state.testRuns)

    useEffect(() => {
        if(isEditing){
            navigate('/testsuites/edit')
            return
        }
        dispatch(loadTestSuites())
    }, [isEditing])

    const handleStartTest = (id: number) => {
        dispatch(startTestRun(id)).then((id) => {
            setRunningTestId(id ?? -1)
            setOpen(true);
        }).catch(err => {
            setError({open: true, msg: `Error - cannot start the test run - ${err}`})
        })
    }

    const [open, setOpen] = useState(false);
    const [error, setError] = useState({open: false, msg: ''});
    const [runningTestId, setRunningTestId] = useState(-1);

    const handleClose = () => {
        setOpen(false);
    }

    const handleCloseError = () => {
        setError({...error, open: false});
    }
    
    const handleEditTestSuite = (id: number) => {
        dispatch(setTestSuiteEditing(id))
    }

    const handleAddTestSuite = () => {
        dispatch(addTestSuite())
    }

    const handleCloneTestSuite = (id: number) => {
        dispatch(cloneTestSuite(id))
    }

    //Delete dialog
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false)
    const [deleteSuiteId, setDeleteSuiteId] = useState<number>()

    const handleDeleteTestSuite = (id: number) => {
        setDeleteSuiteId(id)
        setDeleteDialogIsOpen(true)
    }
    const handleDeleteCancel = () => {
        setDeleteDialogIsOpen(false)
    }

    const handleDelete = () => {
        dispatch(removeTestSuite(deleteSuiteId!))
        setDeleteDialogIsOpen(false)
    }

    const handleAddFavourite = (id: number) => {
        dispatch(addFavourite(id))
    }

    const handleRemoveFavourite = (id: number) => {
        dispatch(removeFavourite(id))
    }

    const currentPage = useAppSelector(state => state.testSuites.page)

    const [paginationModel, setPaginationModel] = useState<IPaginationState>({
        pageSize: 20,
        page: currentPage,
    });

    return(
        <AppLayout path="/testsuites">
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <Typography component="span" variant="h4" sx={{mb: 0}}>
                        Test Suites
                    </Typography>
                </Grid>
                <Grid item container xs={6} md={6} lg={6} justifyContent="flex-end" alignItems="center">
                    <FavouriteFilter sx={{mr: 2}}></FavouriteFilter>
                    <Button variant="contained" sx={{ }} onClick={handleAddTestSuite} className="btn btn-primary">Add Test Suite</Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '80vh'}}>
                        <DataGrid
                            rows={testSuites}
                            columns={columns(handleStartTest, handleEditTestSuite, handleDeleteTestSuite, handleCloneTestSuite, handleAddFavourite, handleRemoveFavourite)}
                            paginationModel={paginationModel}
                            pageSizeOptions={[20]}
                            onPaginationModelChange={setPaginationModel}
                            disableRowSelectionOnClick={true}
                            getRowHeight={() => 'auto'}
                            initialState={{
                                sorting: {
                                  sortModel: [{ field: 'id', sort: 'asc' }],
                                },
                            }}
                            sx={{
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                   outline: "none !important",
                                },
                                '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                                    marginTop: '1em',
                                    marginBottom: '1em'
                                },
                                '&.MuiDataGrid-root .MuiDataGrid-cell': {
                                    lineHeight: '50px',
                                    whiteSpace: 'nowrap'
                                }
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <TestRunModal testRun={testRuns.testRuns.find(tr => tr.id == runningTestId) } isOpen={open} handleClose={handleClose}/>
            
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open={deleteDialogIsOpen}>
                <DialogTitle>Confirm</DialogTitle>
                <DialogContent dividers>
                    <Typography component="span" variant="body1" sx={{mb: 0}}>
                        Are you sure you want to delete the test suite?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDelete}>Ok</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
                open={error.open}
                onClose={handleCloseError}
                message={error.msg}
                autoHideDuration={5000}
            />
        </AppLayout>
    )
}
