import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { AppLayout } from '../../components/layouts/AppLayout';
import { loadOrganizations, createNewOrganization } from '../../actions/organizationActions';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { IPaginationState } from '../../models/paginationState';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Name', width: 150 },
    // Add more columns as needed
];

export const Organizations: React.FC = () => {
    const dispatch = useAppDispatch();
    const { organizations, isLoading, error } = useAppSelector(state => state.organizations);
    const [newOrgName, setNewOrgName] = useState('');

    useEffect(() => {
        dispatch(loadOrganizations());
    }, [dispatch]);

    const handleCreateOrganization = () => {
        if (newOrgName.trim()) {
            dispatch(createNewOrganization(newOrgName.trim()));
            setNewOrgName('');
        }
    };

    const [paginationModel, setPaginationModel] = useState<IPaginationState>({
        pageSize: 20,
        page: 0,
    });

    return (
        <AppLayout path="/organizations">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        Organisations
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 400 }}>
                        <DataGrid
                            rows={organizations}
                            columns={columns}
                            rowHeight={50}
                            pageSizeOptions={[20]}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            disableRowSelectionOnClick={true}
                            sx={{
                                '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                                    marginTop: '1em',
                                    marginBottom: '1em'
                                }
                            }}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h6" gutterBottom>
                            Create New Organisation
                        </Typography>
                        <TextField
                            label="Organisation Name"
                            variant="outlined"
                            value={newOrgName}
                            onChange={(e) => setNewOrgName(e.target.value)}
                            margin="normal"
                        />
                        <LoadingButton
                            loading={isLoading}
                            variant="contained"
                            onClick={handleCreateOrganization}
                            sx={{ mt: 2 }}
                        >
                            Create Organisation
                        </LoadingButton>
                        {error && (
                            <Typography color="error" sx={{ mt: 2 }}>
                                {error}
                            </Typography>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </AppLayout>
    );
};