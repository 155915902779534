
import { useAppDispatch } from "../../store/store";
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { useAppSelector } from "../../hooks/redux-hooks";
import { AppLayout } from '../../components/layouts/AppLayout';
import { useEffect, useState } from "react";
import { addFavourite, cancelDeviceEditing, clearError, createDevice, loadDevices, removeDevice, removeFavourite, setDeviceEditing, updateDevice, updateRefreshToken } from "../../actions/deviceActions";
import EditIcon from '@mui/icons-material/Edit';
import { Device, Roles, selectFilteredDevices } from "../../reducers/devicesReducer";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Snackbar, Stack, Tooltip, Typography } from "@mui/material";
import { IPaginationState } from "../../models/paginationState";
import { EditDeviceModal } from "./editDeviceModal";
import DeleteIcon from '@mui/icons-material/Delete';
import { grey, red, yellow } from "@mui/material/colors";
import StarIcon from '@mui/icons-material/Star';
import { FavouriteFilter } from "../../components/FavouriteFilter";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/rootReducer";

const columns = (editCallback: (id: number) => void, deleteCallback: (id: number) => void,
    addFavouriteCallback: (id: number) => void, removeFavouriteCallback: (id: number) => void) => {
    return [
        { field: 'id', headerName: 'Id', flex: 1},
        { field: 'username', headerName: 'Name', flex: 1},
        { field: 'platform', headerName: 'Platform', flex: 1},
        { field: 'pushToken', headerName: 'Push Token', flex: 1},
        {
            field: 'actions', headerName: 'Actions', width: 110,
            renderCell: (params: GridRenderCellParams) => (
                <Stack sx={{height: '100%', alignItems: 'center'}} direction="row" spacing={1} useFlexGap={true}>
                    <EditIcon onClick={() => {editCallback(params.row.id)}}/>
                    <Tooltip title="Delete the Device"><DeleteIcon sx={{ color: red[500] }}  onClick={() => {deleteCallback(params.row.id)}}/></Tooltip>   
                    {params.row.favourite && 
                        <Tooltip title="Remove Favourite"><StarIcon sx={{ color: yellow[700] }}  onClick={() => {removeFavouriteCallback(params.row.id)}}/></Tooltip>                    
                    }                    
                    {!params.row.favourite && 
                        <Tooltip title="Favourite the Card"><StarIcon sx={{ color: grey[400] }}  onClick={() => {addFavouriteCallback(params.row.id)}}/></Tooltip>                    
                    }                 
                </Stack>
            )
        },
    ];
}

export const Devices = () =>  {
    const dispatch = useAppDispatch()
    
    const [editingDevice, setEditingDevice] = useState<Device | undefined>();

    var devices = useSelector((state: RootState) => {
        let refreshToken = state.devices.devices.find(device => device.id == editingDevice?.id)?.refreshToken
        if(refreshToken && editingDevice?.refreshToken != refreshToken){
            setEditingDevice({...editingDevice, ...{refreshToken: refreshToken ?? ""}} as Device)
        }
        return selectFilteredDevices(state)
    })

    useEffect(() => {
        dispatch(loadDevices())
    }, [])

    const handleAddDevice = () => {
        setEditingDevice({username: '', roles: [Roles.Device]} as Device)
        dispatch(setDeviceEditing())
    }

    const handleEditAccount = (id: number) => {
        dispatch(updateRefreshToken(id))
        setEditingDevice(devices.find(account => account.id === id))
        dispatch(setDeviceEditing())
    }

    const handleClose = () => {
        dispatch(cancelDeviceEditing())
    }

    const handleErrorClose = () => {
        dispatch(clearError())
    }

    const handleSubmit = async() => {
        dispatch(updateDevice(editingDevice!))
    }

    const handleCreate = async() => {
        dispatch(createDevice(editingDevice!))
    }

    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false)
    const [deleteDeviceId, setDeleteDeviceId] = useState<number>()

    const handleDeleteDevice = (id: number) => {
        setDeleteDeviceId(id)
        setDeleteDialogIsOpen(true)
    }
    const handleDeleteCancel = () => {
        setDeleteDialogIsOpen(false)
    }

    const handleDelete = () => {
        dispatch(removeDevice(deleteDeviceId!))
        setDeleteDialogIsOpen(false)
    }

    const [paginationModel, setPaginationModel] = useState<IPaginationState>({
        pageSize: 20,
        page: 0,
    });

    const handleAddFavourite = (id: number) => {
        dispatch(addFavourite(id))
    }

    const handleRemoveFavourite = (id: number) => {
        dispatch(removeFavourite(id))
    }
    
    var open = useAppSelector(state => state.devices.editing.isEditing)
    var isUpdating = useAppSelector(state => state.devices.editing.isUpdating)
    var editError = useAppSelector(state => state.devices.editing.error)
    var error = useAppSelector(state => state.devices.error)

    return(
        <AppLayout path="/devices">
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <Typography component="span" variant="h4" sx={{mb: 0}}>
                        Devices
                    </Typography>
                </Grid>
                <Grid item container xs={6} md={6} lg={6} justifyContent="flex-end" alignItems="center">
                    <FavouriteFilter sx={{mr: 2}}></FavouriteFilter>
                    <Button variant="contained" sx={{ }} onClick = {handleAddDevice} className="btn btn-primary">Add Device</Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '80vh'}}>
                        <DataGrid
                            rowHeight={50}
                            rows={devices}
                            columns={columns(handleEditAccount, handleDeleteDevice, handleAddFavourite, handleRemoveFavourite)}
                            paginationModel={paginationModel}
                            pageSizeOptions={[20]}
                            onPaginationModelChange={setPaginationModel}
                            disableRowSelectionOnClick={true}
                            initialState={{
                                sorting: {
                                  sortModel: [{ field: 'id', sort: 'asc' }],
                                },
                            }}
                            sx={{
                                '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                                    marginTop: '1em',
                                    marginBottom: '1em'
                                }
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>

            <Snackbar
                open={error != undefined}
                autoHideDuration={3000}
                onClose={handleErrorClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                message={"Device not deleted - "+error?.message}
            />

            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open={deleteDialogIsOpen}>
                <DialogTitle>Confirm</DialogTitle>
                <DialogContent dividers>
                    <Typography component="span" variant="body1" sx={{mb: 0}}>
                        Are you sure you want to delete this device?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDelete}>Ok</Button>
                </DialogActions>
            </Dialog>
            
            <EditDeviceModal device={editingDevice} error={editError.message} isOpen={open} isUpdating={isUpdating} handleClose={handleClose} handleUpdate={setEditingDevice} handleSubmit={handleSubmit} handleCreate={handleCreate}/>
        </AppLayout>
    )
}
