import { combineReducers } from '@reduxjs/toolkit'
import { authSlice } from './authReducer'
import { cardsSlice } from './cardsReducer'
import { devicesSlice } from './devicesReducer'
import { testRunslice } from './testRunsReducer'
import { testSuitesSlice } from './testSuitesReducer'
import { usersSlice } from './usersReducer'
import { mdesSlice } from './mdesReducer'
import organizationsReducer from './organizationsReducer'
import { settingsSlice } from './settingsReducer'

const rootReducer = combineReducers({
    auth: authSlice.reducer,
    testSuites: testSuitesSlice.reducer,
    testRuns: testRunslice.reducer,
    devices: devicesSlice.reducer,
    cards: cardsSlice.reducer,
    users: usersSlice.reducer,
    mdes: mdesSlice.reducer,
    organizations: organizationsReducer,
    settings: settingsSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer