import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { createSession } from "../api/signIn";
import { Account, authSlice, selectAccessToken, selectOrgId } from "../reducers/authReducer";
import { RootState } from "../reducers/rootReducer";
import { putAccount } from "../api/users";
import { store } from "../store/store";
import SocketEventHandler from "../service/socketEventHandler";
import { testRunsActions } from "./testRunActions";
import { testRunslice } from "../reducers/testRunsReducer";
import { testSuiteActions } from "./testSuiteActions";
import { testSuitesSlice } from "../reducers/testSuitesReducer";
import { cardsSlice } from "../reducers/cardsReducer";
import { devicesSlice } from "../reducers/devicesReducer";

export const authActions = authSlice.actions

export const signIn=(username: string, password: string): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        return createSession(username, password).then( response => {
            dispatch(authActions.setAuthSuccess(response))
            SocketEventHandler.getInstance().connect(selectAccessToken(store.getState())!, selectOrgId(store.getState())!)
        }).catch(err => {
            dispatch(authActions.setAuthFailed({message: "Invalid username or password"}))
        })
    }
}

export const signOut=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        dispatch(testRunslice.actions.setReset())
        dispatch(testSuitesSlice.actions.setReset())
        dispatch(cardsSlice.actions.setReset())
        dispatch(devicesSlice.actions.setReset())
        dispatch(authActions.setLogOut())
        SocketEventHandler.getInstance().disconnect()
    }
}

export const checkAuthExpired=(err: any): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        if(axios.isAxiosError(err) && (err as AxiosError).response?.status === 401) {
            window.localStorage.clear()
            dispatch(signOut())
        }
    }
}

export const updateAccount=(account: Account): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        dispatch(authActions.setIsUpdating(true))
        return putAccount(getState().auth.accessToken || "", account).then( response => {
            dispatch(authActions.setUpdateSuccess(response))
        }).catch(err => {
            dispatch(authActions.setUpdateFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const setAccountEditing=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        return dispatch(authActions.setIsEditing(true))
    }
}

export const cancelAccountEditing=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        return dispatch(authActions.setIsEditing(false))
    }
}