import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Error {
    message: string
}

interface AuditInfo {
    UserId: string
    UserName: string,
    Organization: string,
    Phone: string
}

export interface TokenStatus{
    StatusCode: string,
    StatusDescription: string,
    StatusDateTime: string,
    Initiator: string,
    CommentId: string,
    ReasonCode: string,
    AuditInfo: AuditInfo
}

export interface MdesState{
    tokenStatusHistory: TokenStatus[]
    tokenTransactions: Transaction[]
    tokenId: String
    isLoading: boolean
    error: Error
}

export const initialState: MdesState = {
    tokenStatusHistory: [],
    tokenTransactions: [],
    isLoading: false,
    tokenId: "",
    error: {message: ""}
}

export interface Transaction{
    TransactionDateTime: string
    CurrencyCode: string
    TransactionAmount: string
    TransactionTypeCode: string
    TransactionTypeDescription: string
    TransactionStatusCode: string
    MerchantName: string
    MerchantCategoryCode: string
    MerchantCategoryDescription: string
    POSEntryMode: string
  }

export interface StatePayload{
    tokenId: String,
    history: TokenStatus[],
    transactions: Transaction[]
}

export const mdesSlice = createSlice({
    name: 'mdes',
    initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoading = payload
            state.tokenStatusHistory = []
            state.tokenTransactions = []
        },
        setLoadSuccess: (state, { payload }: PayloadAction<StatePayload>) => {
            state.tokenStatusHistory = payload.history.length == 0 ? [] : payload.history
            state.tokenTransactions = payload.transactions.length == 0 ? [] : payload.transactions
            state.tokenId = payload.tokenId
            state.error = {message: ""}
            state.isLoading = false
        },
        setLoadFailed: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.tokenStatusHistory = []
            state.tokenTransactions = []
            state.isLoading = false
        }
    },
})

export const { setLoadSuccess, setLoading, setLoadFailed} = mdesSlice.actions
