import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Organization {
    id: number;
    name: string;
    // Add more fields as needed
}

export interface OrganizationsState {
    organizations: Organization[];
    isLoading: boolean;
    error: string | null;
}

const initialState: OrganizationsState = {
    organizations: [],
    isLoading: false,
    error: null,
}

export const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
        setOrganizations: (state, action: PayloadAction<Organization[]>) => {
            state.organizations = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        addOrganization: (state, action: PayloadAction<Organization>) => {
            state.organizations.push(action.payload);
        },
    },
})

export const { setOrganizations, setLoading, setError, addOrganization } = organizationsSlice.actions;

export default organizationsSlice.reducer;