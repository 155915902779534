import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../reducers/rootReducer";
import { getOrganizations, createOrganization } from "../api/organizations";
import { setOrganizations, setLoading, setError, addOrganization } from "../reducers/organizationsReducer";
import { checkAuthExpired } from "./authActions";

export const loadOrganizations = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true));
        try {
            const response = await getOrganizations(getState().auth.accessToken || "");
            dispatch(setOrganizations(response));
        } catch (err: any) {
            dispatch(setError(err.message || "Failed to load organizations"));
            dispatch(checkAuthExpired(err));
        }
    }
}

export const createNewOrganization = (name: string): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true));
        try {
            const response = await createOrganization(getState().auth.accessToken || "", { name });
            dispatch(addOrganization(response));
        } catch (err: any) {
            dispatch(setError(err.message || "Failed to create organization"));
            dispatch(checkAuthExpired(err));
        }
    }
}