import { Box, CircularProgress, Dialog, Stack, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { ITestRun, TestState } from "../../models/testRun"


const ContentStyle = styled(Box)({
    padding: 25,    
});

interface Props {
    testRun?: ITestRun, 
    isOpen: boolean,
    handleClose: () => void
}

export const TestRunModal = ({testRun, isOpen, handleClose}: Props) => {
    return(
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            maxWidth="lg"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            {testRun &&
                <ContentStyle>
                    <Typography variant="h5" noWrap component="span" sx={{mb: 2}}>{testRun?.name}</Typography>
                    <Stack direction="row" spacing={3}>
                        <Stack direction="column" spacing={0.5}>
                            <Typography variant="body2" sx={{fontWeight: 'bold'}} noWrap component="span">Id</Typography>
                            <Typography variant="body2" sx={{fontWeight: 'bold'}} noWrap component="span">Started</Typography>
                            <Typography variant="body2" sx={{fontWeight: 'bold'}} noWrap component="span">Completed</Typography>
                            <Typography variant="body2" sx={{fontWeight: 'bold'}} noWrap component="span">Result</Typography>
                        </Stack>
                        <Stack direction="column" spacing={0.5}>
                            <Typography variant="body2" noWrap component="span">{testRun.id}</Typography>
                            <Typography variant="body2" noWrap component="span">{testRun.startTimeStr}</Typography>
                            <Typography variant="body2" noWrap component="span">{testRun.endTimeStr}</Typography>
                            <Typography variant="body2" noWrap component="span">{testRun.result}</Typography>
                        </Stack>
                    </Stack>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell> 
                                <TableCell>Type</TableCell> 
                                <TableCell>State</TableCell> 
                                <TableCell>Start</TableCell> 
                                <TableCell>End</TableCell> 
                                <TableCell>Duration (ms)</TableCell> 
                                <TableCell>Result</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...testRun.testResults].sort((a,b) => (a.id ?? 0) - (b.id ?? 0)).map(testResult =>
                                <TableRow key={testResult.id}>
                                    <TableCell>{testResult.id}</TableCell> 
                                    <TableCell>{testResult.testType}</TableCell> 
                                    <TableCell>
                                        {testResult.state}
                                    </TableCell> 
                                    <TableCell>{testResult.startTimeStr}</TableCell> 
                                    <TableCell>{testResult.endTimeStr}</TableCell> 
                                    <TableCell>{testResult.duration}</TableCell> 
                                    <TableCell>{testResult.result}</TableCell>
                                    <TableCell>
                                        <Table size="small">
                                            {[...testResult.data].map(item =>
                                                <TableRow sx={{padding: 0}}>
                                                    <TableCell sx={{padding: 0, fontSize: '12px'}}>{item.key}</TableCell>
                                                    <TableCell sx={{whiteSpace:"nowrap", padding: 0, fontSize: '12px'}}>
                                                        {item.key == "tur"
                                                            ? <a href={'/mdes/'+item.value}>{item.value}</a>
                                                            : item.value
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </Table>
                                    </TableCell> 
                                    <TableCell>
                                        {[TestState.Running, TestState.Pushed].includes(testResult.state) && testRun.state === TestState.Running &&
                                            <CircularProgress size="1.1rem"/>
                                        }
                                    </TableCell> 
                                </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </ContentStyle>
            }   
        </Dialog>
    )
}