import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../reducers/rootReducer";
import { checkAuthExpired } from "./authActions";
import { getTokenStatusHistory, getTokenTransactions } from "../api/mdes";
import { mdesSlice } from "../reducers/mdesReducer";
import axios from "axios";
import { ErrorResponse } from "../models/errorResponse";

export const mdesActions = mdesSlice.actions

export const loadTokenStatusHistory=(tokenId: string): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        try{
            dispatch(mdesActions.setLoading(true))
            const history = await getTokenStatusHistory(tokenId, getState().auth.accessToken || "")
            const transactions = await getTokenTransactions(tokenId, getState().auth.accessToken || "")
            dispatch(mdesActions.setLoadSuccess({tokenId: tokenId, history: history, transactions: transactions}))
        }catch(err){
            let errMsg = "Failed to get token status"
            if (axios.isAxiosError(err))  {
                const errorResponse = (err.response?.data as ErrorResponse)
                errMsg = errorResponse.error
            }
            dispatch(mdesActions.setLoadFailed({message: errMsg}))
            dispatch(checkAuthExpired(err))
        }
    }
}

