import { Box, styled, Tooltip } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import { yellow, grey } from '@mui/material/colors';
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { setFavouritesOnly } from "../reducers/settingsReducer";

export const FavouriteFilterComponent = ({...props}) => {
    const dispatch = useAppDispatch()
    const favouritesOnly = useAppSelector(state => state.settings.favouritesOnly);

    const handleFavouritesFilter = () => {
        dispatch(setFavouritesOnly(!favouritesOnly))
    }
  
    return (
      <Box onClick={handleFavouritesFilter} {...props}>
          {favouritesOnly && 
              <Tooltip title="Show all"><StarIcon sx={{ color: yellow[700] }}/></Tooltip>                    
          }                    
          {!favouritesOnly && 
              <Tooltip title="Show favourites only"><StarIcon sx={{ color: grey[400] }}/></Tooltip>                    
          }
      </Box>
    )
  }
  
  export const FavouriteFilter = styled(FavouriteFilterComponent)``
  