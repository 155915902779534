import { Box, Container, Paper, Stack, styled } from "@mui/material";
import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField/TextField";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signIn } from "../../actions/authActions"
import { useAppSelector } from "../../hooks/redux-hooks";
import { useAppDispatch } from "../../store/store";
import { PasswordField } from "../../components/PasswordField";

const RootStyle = styled("div")({
    background: "rgb(230, 230, 230)",
    height: "100vh",
    display: "grid",
    placeItems: "center",
});

const ContentStyle = styled(Box)({
    maxWidth: 400,
    padding: 25,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    background: "#fff",
});

export const SignIn = () =>  {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    let [username, setUsername] = useState<string>("");
    let [password, setPassword] = useState<string>("");

    const authError = useAppSelector(state => state.auth.error)
    const signedIn = useAppSelector(state => state.auth.isAuth)

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        dispatch(signIn(username, password))
        e.preventDefault()
    }

    useEffect(() => {
        if(signedIn){
            navigate('/dashboard')
        }
    })

    return (
        <RootStyle>
            <Container maxWidth="sm">
                <ContentStyle>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <Stack spacing={3}>
                            <h3>MDES Monitor</h3>      
                            <TextField id="standard-basic" label="Email/Username" variant="standard" name="username" value={username} onChange={(e) => setUsername(e.target.value)}/>
                            <PasswordField password={password} updatePassword={(e) => {setPassword(e)}}></PasswordField>
                            <Button variant="contained" type="submit" className="btn btn-primary" disabled={(username === "" || password === "")}>Login</Button>
                        </Stack>
                    </form>
                </ContentStyle>            
            </Container>
        </RootStyle>
    )
}

