import { useAppDispatch } from "../../store/store";
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { useAppSelector } from "../../hooks/redux-hooks";
import { AppLayout } from '../../components/layouts/AppLayout';
import { useEffect, useState } from "react";
import { loadTestRuns, updateTestRunsPage } from "../../actions/testRunActions";
import { TestState } from "../../models/testRun";
import { CircularProgress, Grid, Modal, Paper, Typography } from "@mui/material";
import { IPaginationState } from "../../models/paginationState";
import { TestRunModal } from "../modal/TestRunModal";

const columns = () => {
    return [
        { field: 'id', headerName: 'ID', flex: 1,},
        { field: 'name', headerName: 'Name', flex: 1,},
        { field: 'state', headerName: 'State', flex: 1,},
        { field: 'startTime', headerName: 'Started', flex: 1, getOptionValue: (value:any) => `${value.startTimeStr}`},
        { field: 'endTime', headerName: 'Complete', flex: 1, getOptionValue: (value:any) => `${value.endTimeStr}`},
        { field: 'duration', headerName: 'Duration (ms)', flex: 1, getOptionValue: (value:any) => `${value.duration} || '-'`},
        { field: 'result', headerName: 'Result', flex: 1, 
            _renderCell: (params: GridRenderCellParams) => (
                <span>
                    {params.row.state === TestState.Complete ?  params.row.resultStr : ''}
                    {params.row.state === TestState.Running &&
                        <CircularProgress size="1.1rem" style={{'color': 'green'}}/>
                    }
                </span>
            ),
            get renderCell() {
                return this._renderCell;
            },
            set renderCell(value) {
                this._renderCell = value;
            },
        }
    ]
}

export const Dashboard = () =>  {
    const dispatch = useAppDispatch()
    
    var {testRuns, perPage, total} = useAppSelector(state => state.testRuns)

    useEffect(() => {
        dispatch(loadTestRuns())
    }, [])

    const [open, setOpen] = useState(false);
    const [selectedTestRunId, setSelectedTestRunId] = useState<number>();

    const handleOpen = (id: number) => {
        setSelectedTestRunId(id)
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    function handlePageChange(state: IPaginationState): void {
        dispatch(updateTestRunsPage(state.page))
        setPaginationModel(state)
    }

    const [paginationModel, setPaginationModel] = useState<IPaginationState>({
        pageSize: perPage,
        page: 0,
    });

    return(
        <AppLayout path="/dashboard">
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <Typography component="span" variant="h4" sx={{mb: 0}}>
                        Dashboard
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '80vh'}}>
                        <DataGrid
                            rows={testRuns}
                            columns={columns()}
                            pageSizeOptions={[perPage]}
                            rowCount={total}
                            paginationMode="server"
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePageChange}
                            onRowSelectionModelChange={ids => handleOpen(ids[0] as number)}
                            getRowClassName={(params) => `${ params.row.state === "Running" ? 'data-grid-running' : params.row.result === 0 ? 'data-grid-passed' : 'data-grid-failed'}`}
                            sx={{
                                '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                                    marginTop: '1em',
                                    marginBottom: '1em'
                                }
                            }}/>
                    </Paper>
                </Grid>
            </Grid>
            <TestRunModal testRun={testRuns.find(run => run.id === selectedTestRunId)} isOpen={open} handleClose={handleClose}/>
        </AppLayout>
    )
}
