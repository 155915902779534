import { Account } from "../reducers/authReducer"
import api from "./api"

export const getUsers = async (accessToken: string) =>{
    var response=await api().get(
        '/api/v1/accounts', 
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const putAccount = async (accessToken: string, account: Account) =>{
    var response=await api().put(
        '/api/v1/accounts/'+account.id,
        account,
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const deleteAccount = async (accessToken: string, id: number) =>{
    var response=await api().delete(
        '/api/v1/accounts/'+id,
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}