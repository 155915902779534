import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Modal, Select, styled, TextField, Typography } from "@mui/material"
import { Device, Platform, Roles } from "../../reducers/devicesReducer";
import { User } from "../../reducers/usersReducer";
import { useAppDispatch } from "../../store/store";
import { cancelUserEditing, createUser, updateUser } from "../../actions/userActions";
import { useAppSelector } from "../../hooks/redux-hooks";
import { PasswordField } from "../../components/PasswordField";

const ContentStyle = styled(Box)({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 25,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    background: "#fff",
});

interface Props {
    user?: User,
    handleUpdate: (user: User) => void
}

export const EditAccountModal = (props: Props) => {
    const user = props.user

    const dispatch = useAppDispatch()
    
    const handleClose = () => {
        dispatch(cancelUserEditing())
    }

    const handleSubmit = async() => {
        dispatch(updateUser(user!))
    }

    const handleCreate = async() => {
        dispatch(createUser(user!))
    }

    var open = useAppSelector(state => state.users.editing.isEditing)
    var isUpdating = useAppSelector(state => state.users.editing.isUpdating)
    var error = useAppSelector(state => state.users.editing.error)


    const handleRoleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const role = e.target.name as Roles
        var roles = user?.roles || []
        if(checked){
            roles = [...roles, role]
        }else{
            roles = roles.filter(nextRole => nextRole !== role)
        }
        props.handleUpdate({...user!, ...{roles: roles}})
    }

    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <ContentStyle>
                <Box component="form" noValidate sx={{ width: {sm:500} }}>
                    <Typography component="h1" variant="h5" sx={{mb: 3}}>
                        {user?.id ? 'Update User' : 'Add User'}
                    </Typography>

                    <TextField autoFocus={true} autoComplete="off" margin="dense" id="name" label="Username" disabled={user?.id != undefined} InputProps={{readOnly: user?.id ? true : false}}
                        type="text" fullWidth variant="standard" value={user?.username} onChange={(e) => {props.handleUpdate({...user!, ...{username: e.target.value}})}}/>

                    <PasswordField password={user?.password ?? ''} updatePassword={(e) => {props.handleUpdate({...user!, ...{password: e}})}} helperText={user?.id ? 'Leave empty to not update' : ''}></PasswordField>
    
                    <FormGroup sx={{mt:1}}>
                        <FormControlLabel control={<Checkbox checked={user?.blacklist} onChange={(e) => {props.handleUpdate({...user!, ...{blacklist: e.target.checked}})}} />} label="Blacklist" />
                    </FormGroup>

                    <FormGroup sx={{mt:3}}>
                        <FormLabel component="legend">Roles</FormLabel>
                        <FormControlLabel control={<Checkbox onChange={handleRoleChange} name={'Viewer'} checked={user?.roles.includes(Roles.Viewer)}/>} label="Viewer" />
                        <FormControlLabel control={<Checkbox onChange={handleRoleChange} name={'Manager'} checked={user?.roles.includes(Roles.Manager)}/>} label="Manager" />
                        <FormControlLabel control={<Checkbox onChange={handleRoleChange} name={'Admin'} checked={user?.roles.includes(Roles.Admin)}/>} label="Admin" />
                    </FormGroup>

                    <Typography component="p" variant="body1" color="red" sx={{mt: 2}}>
                        {error.message}
                    </Typography>
                    
                    {user?.id
                        ? <LoadingButton variant="contained" loadingIndicator="Updating…" sx={{ mt: 3, mb: 2 }} loading={isUpdating} className="btn btn-primary" onClick={handleSubmit}>Update</LoadingButton>
                        : <LoadingButton variant="contained" loadingIndicator="Creating…" sx={{ mt: 3, mb: 2 }} loading={isUpdating} className="btn btn-primary" onClick={handleCreate}>Create</LoadingButton>
                    }
                </Box>
            </ContentStyle> 
        </Modal>
    )
}