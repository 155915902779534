import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { getDevices, getDeviceRefreshToken, postDevice, putDevice, deleteDevice, addFavouriteDevice, deleteFavouriteDevice } from "../api/devices";
import { Device } from "../reducers/devicesReducer";
import { RootState } from "../reducers/rootReducer";
import { devicesSlice } from "../reducers/devicesReducer";
import { checkAuthExpired } from "./authActions";

export const deviceActions = devicesSlice.actions

export const loadDevices=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return getDevices(getState().auth.accessToken || "").then( response => {
            dispatch(deviceActions.setLoadSuccess({devices: response.devices.sort((a,b) => a.username.localeCompare(b.username)), favourites: response.favourites}))
        }).catch(err => {
            dispatch(deviceActions.setLoadFailed({message: "Failed to get test devices:"+err}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const updateRefreshToken=(deviceId: number): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return getDeviceRefreshToken(getState().auth.accessToken || "", deviceId).then( response => {
            dispatch(deviceActions.setUpdateDeviceRefreshToken(response))
        }).catch(err => {
            dispatch(checkAuthExpired(err))
        })
    }
}

export const updateDevice=(device: Device): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        dispatch(deviceActions.setIsUpdating(true))
        console.log(JSON.stringify(device))
        return putDevice(getState().auth.accessToken || "", device).then( response => {
            dispatch(deviceActions.setUpdateSuccess())
            dispatch(loadDevices())
        }).catch(err => {
            dispatch(deviceActions.setUpdateFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const createDevice=(device: Device): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        dispatch(deviceActions.setIsUpdating(true))
        return postDevice(getState().auth.accessToken || "", device).then( response => {
            dispatch(deviceActions.setUpdateSuccess())
            dispatch(loadDevices())
        }).catch(err => {
            dispatch(deviceActions.setUpdateFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const removeDevice=(id: number): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return deleteDevice(getState().auth.accessToken || "", id).then( response => {
            dispatch(loadDevices())
        }).catch(err => {
            dispatch(deviceActions.setDeleteFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const addFavourite=(id: number): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return addFavouriteDevice(getState().auth.accessToken || "", id).then( _ => {
            dispatch(loadDevices())
        }).catch(err => {
            dispatch(deviceActions.setDeleteFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const removeFavourite=(id: number): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return deleteFavouriteDevice(getState().auth.accessToken || "", id).then( _ => {
            dispatch(loadDevices())
        }).catch(err => {
            dispatch(deviceActions.setDeleteFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const cancelDeviceEditing=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        return dispatch(deviceActions.setIsEditing(false))
    }
}

export const clearError=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        return dispatch(deviceActions.setClearError())
    }
}

export const setDeviceEditing=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        return dispatch(deviceActions.setIsEditing(true))
    }
}