import api from "./api"
import { Organization } from "../reducers/organizationsReducer"

export const getOrganizations = async (accessToken: string) => {
    const response = await api().get(
        '/api/v1/organizations', 
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + accessToken
            },
        }
    )
    return response.data
}

export const createOrganization = async (accessToken: string, organization: Omit<Organization, 'id'>) => {
    const response = await api().post(
        '/api/v1/organizations',
        organization,
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + accessToken
            },
        }
    )
    return response.data
}