import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel } from "@mui/material";
import { useState } from "react";

interface Props {
    password: string
    helperText?: string
    updatePassword: (password: string) => void,
}

export const PasswordField = (props: Props) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };
  
    const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    return(
        <FormControl margin="dense" variant="standard" fullWidth>
        <InputLabel htmlFor="password">Password</InputLabel>
        <Input
            id="password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                    >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            }
            onChange={(e) => {props.updatePassword(e.target.value)}}
        />
        {props.helperText && <FormHelperText id="password-helper-text">{props.helperText}</FormHelperText>}
        </FormControl>
    )
}