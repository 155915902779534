
import { useAppDispatch } from "../../store/store";
import { useAppSelector } from "../../hooks/redux-hooks";
import { AppLayout } from '../../components/layouts/AppLayout';
import { useEffect, useState } from "react";
import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { SuiteState, TestConfig, TestSuite } from "../../reducers/testSuitesReducer";
import { loadDevices } from "../../actions/deviceActions";
import { loadCards } from "../../actions/cardActions";
import { red} from '@mui/material/colors';
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import { cancelTestSuiteEditing, createTestSuite, updateTestSuite } from "../../actions/testSuiteActions";
import { EditTest } from "./EditTest";

export const EditTestSuite = () =>  {
    const [testSuite, setTestSuite] = useState<TestSuite>()
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    var isUpdating = useAppSelector(state => state.testSuites.editing.isUpdating)
    var error = useAppSelector(state => state.testSuites.editing.error)
    var devices = useAppSelector(state => state.devices.devices)
    var cards = useAppSelector(state => state.cards.cards)
    var editingTestSuite = useAppSelector(state => state.testSuites.editing).testSuite
    var isEditing = useAppSelector(state => state.testSuites.editing.isEditing)

    useEffect(() => {
        if(!isEditing){
            navigate('/testsuites')
            return
        }
        setTestSuite(editingTestSuite)
        dispatch(loadDevices())
        dispatch(loadCards())
    }, [editingTestSuite, isEditing])

    const handleTestNameChange = (name: string) => setTestSuite({...testSuite!, ...{name: name}})

    const handleScheduleFrequencyChange = (scheduleFrequency: number) => setTestSuite({...testSuite!, ...{scheduleFrequency: scheduleFrequency}})

    const handleSuiteStateChange = (state: SuiteState) => { setTestSuite({...testSuite!, ...{state: state}}) }


    const handleDeleteTest = (index: number) => () => {
        setTestSuite({...testSuite!, ...{tests: testSuite!.tests.filter((_, i) => i !== index)}})
    }

    const handleTestChange = (index: number) => (updatedTest: TestConfig) => {
        setTestSuite({...testSuite!, ...{tests: testSuite!.tests.map((test, i) => i === index ? updatedTest : test)}})
    }

    const handleAddTest = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setTestSuite({...testSuite!, ...{tests: testSuite!.tests.concat({} as TestConfig)}})
    }

    const handleSubmit = async() => {
        dispatch(updateTestSuite(testSuite!))
    }

    const handleCreate = async() => {
        dispatch(createTestSuite(testSuite!))
    }

    const handleCancel = () => {
        dispatch(cancelTestSuiteEditing())
    }

    return(
        <AppLayout path="/testsuites">
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography component="span" variant="h4" sx={{mb: 0}}>
                        {testSuite?.id ? 'Update Test Suite' : 'Add Test Suite'}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column'}}>
                        <Box component="form" noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField autoFocus margin="dense" id="name" label="Name"
                                        type="text" fullWidth variant="standard" value={testSuite?.name} onChange={(e) => {handleTestNameChange(e.target.value)}}
                                        InputLabelProps={{ shrink: (testSuite?.name !== "") }} />
                                </Grid>

                                <Grid item xs={12} md={6}></Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl sx={{ mt: 2, mb: 1 }} size="small" fullWidth>
                                        <InputLabel id="type-select-state">Scheduled or Manual</InputLabel>
                                        <Select
                                            labelId="type-select-state"
                                            id="suite-state"
                                            value={testSuite?.state ?? SuiteState.Manual}
                                            label="Scheduled or Manual"
                                            onChange={(e) => {handleSuiteStateChange(e.target.value as SuiteState)}}>
                                            <MenuItem value={SuiteState.Manual}>Manual execution</MenuItem>
                                            <MenuItem value={SuiteState.Scheduled}>Scheduled execution</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>                                  
                                    <TextField autoFocus margin="dense" id="scheduleFrequency" label="Scheduled Frequence (minutes)"
                                        type="number" fullWidth variant="standard" value={testSuite?.scheduleFrequency} onChange={(e) => {handleScheduleFrequencyChange(parseFloat(e.target.value))}}
                                        InputLabelProps={{ shrink: (testSuite?.scheduleFrequency !== undefined) }} InputProps={{ inputProps: { min: 0, max: 10, step: '1'} }} />
                                </Grid>

                                <Grid item xs={12} md={12} sx={{mt: 3}}>  
                                    <Typography component="span" variant="h6" sx={{mb: 3, mt: 30}}>
                                        Tests
                                    </Typography>
                                    <Divider/>
                                </Grid>
                            </Grid>

                            <Box sx={{mt: 3}}>
                                {testSuite?.tests.map((test, index) =>
                                    <EditTest key={index} test={test} devices={devices} cards={cards} handleTestChange={handleTestChange(index)} handleDeleteTest={handleDeleteTest(index)}></EditTest>
                                )}
                            </Box>

                            <Typography component="p" variant="body1" sx={{color: red[500], mt:2}} >
                                {error.message}
                            </Typography>

                            {testSuite?.id
                                ? <LoadingButton variant="contained" loadingIndicator="Updating…" sx={{ mt: 3, mb: 2 }} loading={isUpdating} className="btn btn-primary" onClick={handleSubmit}>Update</LoadingButton>
                                : <LoadingButton variant="contained" loadingIndicator="Updating…" sx={{ mt: 3, mb: 2 }} loading={isUpdating} className="btn btn-primary" onClick={handleCreate}>Create</LoadingButton>
                            }

                            <Button variant="outlined" sx={{ mt: 3, mb: 2, ml: 2 }} className="btn btn-primary" onClick={handleCancel}>Cancel</Button>
                            <Button variant="outlined" sx={{ mt: 3, mb: 2, ml: 2 }} className="btn btn-primary" onClick={handleAddTest}>Add test</Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </AppLayout>
    )
}
