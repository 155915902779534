
import { useAppDispatch } from "../../store/store";
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { useAppSelector } from "../../hooks/redux-hooks";
import { AppLayout } from '../../components/layouts/AppLayout';
import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import { Platform } from "../../reducers/devicesReducer";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Snackbar, Stack, Tooltip, Typography } from "@mui/material";
import { EditAccountModal } from "./editAccountModal";
import { clearError, deleteUser, loadUsers, setUserEditing } from "../../actions/userActions";
import { User } from "../../reducers/usersReducer";
import { IPaginationState } from "../../models/paginationState";
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from "@mui/material/colors";

const columns = (editCallback: (id: number) => void, deleteCallback: (id: number) => void) => {
    return [
        { field: 'id', headerName: 'Id', flex: 1},
        { field: 'username', headerName: 'Username', flex: 1},
        { field: 'roles', headerName: 'Roles', flex: 1},
        { field: 'blacklist', headerName: 'Blacklist', flex: 1},
        {
            field: 'actions', headerName: 'Actions', width: 80,
            renderCell: (params: GridRenderCellParams) => (
                <Stack sx={{height: '100%', alignItems: 'center'}} direction="row" spacing={1} useFlexGap={true}>
                    <Tooltip title="Edit the user account"><EditIcon onClick={() => {editCallback(params.row.id)}}/></Tooltip>        
                    <Tooltip title="Delete the user account"><DeleteIcon sx={{ color: red[500] }}  onClick={() => {deleteCallback(params.row.id)}}/></Tooltip>                    
                </Stack>
            )
        },
    ];
}

export const Users = () =>  {
    const dispatch = useAppDispatch()
    
    var data = useAppSelector(state => state.users)
    const [editingAccount, setEditingAccount] = useState<User | undefined>();

    useEffect(() => {
        dispatch(loadUsers())}, []
    )

    const handleAddUser = () => {
        setEditingAccount({platform: Platform.Web, roles: []} as unknown as User)
        dispatch(setUserEditing())
    }

    const handleEditAccount = (id: number) => {
        setEditingAccount(data.users.find(account => account.id === id))
        dispatch(setUserEditing())
    }

    const handleDelete = () => {
        dispatch(deleteUser(deleteAccountId!))
        setDeleteDialogIsOpen(false)
    }

    const handleDeleteCancel = () => {
        setDeleteDialogIsOpen(false)
    }

    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false)
    const [deleteAccountId, setDeleteAccountId] = useState<number>()
    const handleDeleteAccount = (id: number) => {
        setDeleteAccountId(id)
        setDeleteDialogIsOpen(true)
    }

    const [paginationModel, setPaginationModel] = useState<IPaginationState>({
        pageSize: 20,
        page: 0,
    });
    
    return(
        <AppLayout path="/users">
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <Typography component="span" variant="h4" sx={{mb: 0}}>
                        User Accounts
                    </Typography>
                </Grid>
                <Grid item container xs={6} md={6} lg={6} justifyContent="flex-end">
                    <Button variant="contained" sx={{ }} onClick = {handleAddUser} className="btn btn-primary">Add User</Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '80vh'}}>
                        <DataGrid
                            rowHeight={50}
                            rows={data.users}
                            columns={columns(handleEditAccount, handleDeleteAccount)}
                            pageSizeOptions={[20]}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            disableRowSelectionOnClick={true}
                            sx={{
                                '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                                    marginTop: '1em',
                                    marginBottom: '1em'
                                }
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>
            
            <ErrorSnackBar/>

            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open={deleteDialogIsOpen}>
                <DialogTitle>Confirm</DialogTitle>
                <DialogContent dividers>
                    <Typography component="span" variant="body1" sx={{mb: 0}}>
                        Are you sure you want to delete this user?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDelete}>Ok</Button>
                </DialogActions>
            </Dialog>
            
            <EditAccountModal user={editingAccount} handleUpdate={setEditingAccount}/>
        </AppLayout>
    )
}


export const ErrorSnackBar = () => {
    const dispatch = useAppDispatch()
    var error = useAppSelector(state => state.users.error)
    
    const handleErrorClose = () => {
        dispatch(clearError())
    }

    return(
        <Snackbar
            open={error != undefined}
            autoHideDuration={3000}
            onClose={handleErrorClose}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            message={"User not deleted - "+error?.message}
        />
    )
}
