import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './rootReducer'

export interface AuthError {
    message: string
}

export interface AuthState {
    isAuth: boolean
    account?:Account
    accessToken?: string
    refreshToken?: string
    isLoading: boolean
    error: AuthError
    editing: {
        isEditing: boolean,
        isUpdating: boolean,
        error: AuthError
    }
}

export interface Account{
    id: number
    username: string
    roles: string[],
    organisationId: number
    organisationName: string
}

export interface JwtCredentials{
    accessToken: string
    refreshToken: string
    account:Account
}

export const initialState: AuthState = {
    isAuth: false,
    isLoading: false,
    error: {message: ""},
    editing: {
        isEditing: false,
        isUpdating: false,
        error: {message: ""}
    }
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setAuthSuccess: (state, { payload }: PayloadAction<JwtCredentials>) => {
            state.accessToken = payload.accessToken
            state.refreshToken = payload.refreshToken
            state.account = payload.account
            state.error = {message: ""}
            state.isAuth = true
        },
        setLogOut: (state) => {
            state.isAuth = false
            state.account = undefined
        },
        setAuthFailed: (state, { payload }: PayloadAction<AuthError>) => {
            state.error = payload
            state.isAuth = false
        },
        setIsEditing: (state, { payload }: PayloadAction<boolean>) => {
            state.editing.isEditing = payload
            state.editing.isUpdating = false
        },
        setIsUpdating: (state, {payload}: PayloadAction<boolean>) => {
            state.editing.isUpdating = true
        },
        setUpdateFailed:  (state, { payload }: PayloadAction<AuthError>) => {
            state.editing.error = payload
            state.editing.isUpdating = false
        },
        setUpdateSuccess:  (state, { payload }: PayloadAction<Account>) => {
            state.editing.isEditing = false
            state.editing.isUpdating = false
            state.editing.error = {message: ""}
        }
    },
})

export const selectAccessToken = (state: RootState) => state.auth.accessToken
export const selectOrgId = (state: RootState) => state.auth.account?.organisationId

export const { setAuthSuccess, setLogOut, setLoading, setAuthFailed} = authSlice.actions
