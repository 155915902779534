import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Modal, Select, styled, TextField, Typography } from "@mui/material"
import { Device, Platform, Roles } from "../../reducers/devicesReducer";
import { Card } from "../../reducers/cardsReducer";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { cancelCardEditing, createCard, updateCard } from "../../actions/cardActions";

const ContentStyle = styled(Box)({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 25,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    background: "#fff",
});

interface Props {
    card?: Card,
    handleUpdate: (card: Card) => void
}

export const EditCardModal = (props: Props) => {
    const card = props.card
    const dispatch = useAppDispatch()
    
    const handleClose = () => {
        dispatch(cancelCardEditing())
    }

    const handleSubmit = async() => {
        dispatch(updateCard(card!))
    }

    const handleCreate = async() => {
        dispatch(createCard(card!))
    }

    var open = useAppSelector(state => state.cards.editing.isEditing)
    var error = useAppSelector(state => state.cards.editing.error ?? state.cards.error)
    var isUpdating = useAppSelector(state => state.cards.editing.isUpdating)

    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <ContentStyle>
                <Box component="form" noValidate sx={{ width: {sm:500} }}>
                    <Typography component="h1" variant="h5" sx={{mb: 3}}>
                        {card?.id ? 'Update Card' : 'Add Card'}
                    </Typography>

                    <TextField autoFocus margin="dense" id="name" label="Name"
                        type="text" fullWidth variant="standard" value={card?.name} onChange={(e) => {props.handleUpdate({...card!, ...{name: e.target.value}})}}/>
                    <TextField autoFocus margin="dense" id="opc" label="OPC" sx={{mt: 3}}
                        fullWidth variant="outlined" multiline minRows={4} maxRows={10} value={card?.opc} onChange={(e) => {props.handleUpdate({...card!, ...{opc: e.target.value}})}}/>

                    <Typography component="p" variant="body1" color="red" sx={{mt: 2}}>
                        {error.message}
                    </Typography>
                    {card?.id
                        ? <LoadingButton variant="contained" loadingIndicator="Updating…" sx={{ mt: 3, mb: 2 }} loading={isUpdating} className="btn btn-primary" onClick={handleSubmit}>Update</LoadingButton>
                        : <LoadingButton variant="contained" loadingIndicator="Creating…" sx={{ mt: 3, mb: 2 }} loading={isUpdating} className="btn btn-primary" onClick={handleCreate}>Submit</LoadingButton>
                    }
                </Box>
            </ContentStyle> 
        </Modal>
    )
}