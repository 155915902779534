import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { addFavouriteTestSuite, deleteFavouriteTestSuite, deleteTestSuite, getTestSuite, getTestSuites, postTestSuite, putTestSuite } from "../api/testSuites";
import { RootState } from "../reducers/rootReducer";
import { TestSuite, testSuitesSlice } from "../reducers/testSuitesReducer";
import { checkAuthExpired, signOut } from "./authActions";

export const testSuiteActions = testSuitesSlice.actions

export const loadTestSuites=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return getTestSuites(getState().auth.accessToken || "").then( response => {
            dispatch(testSuiteActions.setLoadSuccess(response))
        }).catch(err => {
            dispatch(checkAuthExpired(err))
            dispatch(testSuiteActions.setLoadFailed({message: "Failed to get test suites:"+err.response.data.error}))
        })
    }
}

export const updateTestSuite=(testSuite: TestSuite): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        dispatch(testSuiteActions.setIsUpdating(true))
        return putTestSuite(getState().auth.accessToken || "", testSuite).then( response => {
            dispatch(testSuiteActions.setUpdateSuccess(response))
            dispatch(loadTestSuites())
        }).catch(err => {
            dispatch(testSuiteActions.setUpdateFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const createTestSuite=(testSuite: TestSuite): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        dispatch(testSuiteActions.setIsUpdating(true))
        return postTestSuite(getState().auth.accessToken || "", testSuite).then( response => {
            dispatch(testSuiteActions.setUpdateSuccess(response))
            dispatch(loadTestSuites())
        }).catch(err => {
            dispatch(testSuiteActions.setUpdateFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const removeTestSuite=(id: number): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return deleteTestSuite(getState().auth.accessToken || "", id).then( response => {
            dispatch(loadTestSuites())
        }).catch(err => {
            dispatch(testSuiteActions.setUpdateFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const cancelTestSuiteEditing=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        return dispatch(testSuiteActions.setIsEditing(false))
    }
}

export const addTestSuite=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        return dispatch(testSuiteActions.setEditingNewSuite())
    }
}

export const cloneTestSuite=(id: number): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        dispatch(testSuiteActions.setIsEditing(true))
        return getTestSuite(getState().auth.accessToken || "", id).then( response => {
            const originalSuite: TestSuite = response
            const suite: TestSuite = {...originalSuite, id: undefined, name: '', tests: originalSuite.tests.map(test => { return {...test, id: undefined}})}     
            dispatch(testSuiteActions.setCloneEditTestSuite(suite))
        }).catch(err => {
            dispatch(testSuiteActions.setUpdateFailed({message: "Failed to get test suite:"+err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const addFavourite=(id: number): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return addFavouriteTestSuite(getState().auth.accessToken || "", id).then( _ => {
            dispatch(loadTestSuites())
        }).catch(err => {
            dispatch(testSuiteActions.setUpdateFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const removeFavourite=(id: number): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return deleteFavouriteTestSuite(getState().auth.accessToken || "", id).then( _ => {
            dispatch(loadTestSuites())
        }).catch(err => {
            dispatch(testSuiteActions.setUpdateFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const setTestSuiteEditing=(id: number): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        dispatch(testSuiteActions.setIsEditing(true))
        return getTestSuite(getState().auth.accessToken || "", id).then( response => {           
            dispatch(testSuiteActions.setSuiteToEdit(response))
        }).catch(err => {
            dispatch(testSuiteActions.setUpdateFailed({message: "Failed to get test suite:"+err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

