import { Card } from "../reducers/cardsReducer"
import api from "./api"

interface CardListResponse{
    cards: Card[],
    favourites: number[]
}

export const getCards = async (accessToken: string, favouritesOnly: boolean) : Promise<CardListResponse> =>{
    var response=await api().get<CardListResponse>(
        '/api/v1/cards', 
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const postCard = async (accessToken: string, card: Card) =>{
    var response=await api().post(
        '/api/v1/cards',
        card,
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const putCard = async (accessToken: string, card: Card) =>{
    var response=await api().put(
        '/api/v1/cards/'+card.id,
        card,
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const deleteCard = async (accessToken: string, id: number) =>{
    var response=await api().delete(
        '/api/v1/cards/'+id,
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const addFavouriteCard = async (accessToken: string, id: number) =>{
    console.log('addFavouriteCard '+accessToken+" id:"+id)
    var response=await api().post(
        '/api/v1/cards/'+id+'/favourite',
        {},
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const deleteFavouriteCard = async (accessToken: string, id: number) =>{
    var response=await api().delete(
        '/api/v1/cards/'+id+'/favourite',
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}