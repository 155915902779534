import LoadingButton from "@mui/lab/LoadingButton";
import { Box, FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, Modal, OutlinedInput, Select, styled, TextField, Typography } from "@mui/material"
import { Device, Platform } from "../../reducers/devicesReducer";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../store/store";
import { updateRefreshToken } from "../../actions/deviceActions";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { PasswordField } from "../../components/PasswordField";
const QRCode = require('qrcode');

const ContentStyle = styled(Box)({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 25,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    background: "#fff",
});

interface Props {
    device?: Device,
    error: string,
    isOpen: boolean,
    isUpdating: boolean,
    handleClose: () => void,
    handleUpdate: (device: Device) => void,
    handleSubmit: () => void
    handleCreate: () => void
}

export const EditDeviceModal = (props: Props) => {
    const device = props.device

    let barcode = ""
    if(device && device.refreshToken){
        QRCode.toString(device.refreshToken, {
            errorCorrectionLevel: 'L',
            type: 'svg'
        }, function(err: Error, data: string) {
            if (err) throw err;
            barcode = data
        });
    }

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };
  
    const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    return(
        <Modal
            open={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <ContentStyle>
                <Box component="form" noValidate sx={{ width: {sm:500} }}>
                    <Typography component="h1" variant="h5" sx={{mb: 3}}>
                        {device?.id ? 'Update Device' : 'Add Device'}
                    </Typography>

                    <TextField autoFocus autoComplete="off" margin="dense" id="name" label="Username" disabled={device?.id != undefined}
                        type="text" fullWidth variant="standard" value={device?.username} onChange={(e) => {props.handleUpdate({...device!, ...{username: e.target.value}})}}/>
                    <TextField autoFocus autoComplete="off" margin="dense" id="pushToken" label="Push Token"
                        type="text" fullWidth variant="standard" value={device?.pushToken}/>

                    <PasswordField password={device?.password ?? ''} updatePassword={(e) => {props.handleUpdate({...device!, ...{password: e}})}} helperText={device?.id ? 'Leave empty to not update' : ''}></PasswordField>

                    <FormControl sx={{ mt: 3}} size="small" fullWidth>
                        <InputLabel id="demo-simple-select-label">Platform</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={device?.platform ?? ''}
                            size="small"
                            disabled={device?.id != undefined}
                            onChange={(e) => {props.handleUpdate({...device!, ...{platform: e.target.value as Platform}})}}
                            label="Platform">
                            <MenuItem value={Platform.Android}>Android</MenuItem>
                            <MenuItem value={Platform.Windows}>Windows</MenuItem>
                            <MenuItem value={Platform.iOS}>iOS</MenuItem>
                            <MenuItem value={Platform.Web}>Web</MenuItem>
                        </Select>
                    </FormControl>
                    
                    <Typography component="p" variant="body1" color="red" sx={{mt: 2}}>
                        {props.error}
                    </Typography>

                    {device?.id
                        ? <LoadingButton variant="contained" loadingIndicator="Updating…" sx={{ mt: 3, mb: 2 }} loading={props.isUpdating} className="btn btn-primary" onClick={props.handleSubmit}>Update</LoadingButton>
                        : <LoadingButton variant="contained" loadingIndicator="Creating…" sx={{ mt: 3, mb: 2 }} loading={props.isUpdating} className="btn btn-primary" onClick={props.handleCreate}>Submit</LoadingButton>
                    }

                    <Box mt={1} display="flex">
                        <Box m="auto" width={150}>
                            <div dangerouslySetInnerHTML={{__html: barcode}}/>
                        </Box>
                    </Box>

                </Box>
            </ContentStyle> 
        </Modal>
    )
}

