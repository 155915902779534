import { configureStore, Action } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { createTransform, persistReducer, persistStore } from 'redux-persist'
import { ThunkAction } from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import rootReducer, { RootState } from '../reducers/rootReducer'
import { AuthState } from '../reducers/authReducer'

const authTransform = createTransform(
    (inboundState: AuthState, key) => {
      return inboundState
    },
    
    (outboundState: AuthState, key) => {
      return { ...outboundState, editing: {isEditing: false, isUpdating: false, error: {message: ""}}};
    },
    
    { whitelist: ['auth'] }
)

const persistConfig = {
    key: 'root',
    storage,
    transforms: [authTransform],
    whitelist: ['auth']
}
  
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, unknown, Action>
export const useAppDispatch: () => AppDispatch = useDispatch
export const persistor = persistStore(store)
