import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './rootReducer'

export interface Error {
    message: string
}

export enum Platform{
    Unknown = "Unknown",
    Web = "Web",
    Android = "Android",
    iOS = "iOS",
    Windows = "Windows",
}

export enum Roles {
    Device = "Device",
    Viewer = "Viewer",
    Manager = "Manager",
    Admin = "Admin",
    SuperAdmin = "SuperAdmin"
}

export interface Device{
    id: number
    username: string,
    pushToken: string,
    platform: Platform,
    password: string,
    refreshToken: string,
    roles: Roles[],
    favourite: boolean
}

export interface RefreshToken{
    deviceId: number
    refreshToken: string
}

export interface DevicesState{
    devices: Device[]
    isLoading: boolean
    error: Error | undefined
    editing: {
        isEditing: boolean,
        isUpdating: boolean,
        error: Error
    }
}

export const initialState: DevicesState = {
    devices: [],
    isLoading: false,
    error: undefined,
    editing: {
        isEditing: false,
        isUpdating: false,
        error: {message: ""}
    }
}

export const devicesSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        setReset: (state) => {
            state.devices = []
        },
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setLoadSuccess: (state, { payload }: PayloadAction<{devices: Device[], favourites: number[]}>) => {
            const favourites = payload.favourites
            state.devices = payload.devices.map(device => ({...device, favourite: favourites.includes(device.id)}))
            state.error = undefined
            state.isLoading = false
        },
        setClearError: (state) => {
            state.error = undefined
        },
        setLoadFailed: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.devices = []
            state.isLoading = false
        },
        setDeleteFailed: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
        setIsEditing: (state, { payload }: PayloadAction<boolean>) => {
            state.editing.isEditing = payload
            state.editing.isUpdating = false
        },
        setIsUpdating: (state, {}: PayloadAction<boolean>) => {
            state.editing.isUpdating = true
        },
        setUpdateFailed:  (state, { payload }: PayloadAction<Error>) => {
            state.editing.error = payload
            state.editing.isUpdating = false
        },
        setUpdateSuccess:  (state, {}: PayloadAction<void>) => {
            state.editing.isEditing = false
            state.editing.isUpdating = false
            state.editing.error = {message: ""}
        },
        setUpdateDeviceRefreshToken: (state, {payload }: PayloadAction<RefreshToken>) => {
            state.devices.forEach(device => {
                if(device.id == payload.deviceId){
                    device.refreshToken = payload.refreshToken
                }
            })
        }
    }
})

export const selectFilteredDevices = (state: RootState) => {
    const favouritesOnly = state.settings.favouritesOnly
    return state.devices.devices.filter(device => (device.favourite && favouritesOnly) || !favouritesOnly)
}


export const { setLoadSuccess, setLoading, setLoadFailed, setIsEditing, setIsUpdating, setClearError, setUpdateFailed} = devicesSlice.actions
