import api from "./api"

export const getTestRuns = async (accessToken: string, page: number, perPage: number) =>{
    var response=await api().get(
        '/api/v1/testruns?perpage='+perPage+'&page='+page, 
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const getTestRun = async (accessToken: string, id:number) =>{
    var response=await api().get(
        '/api/v1/testruns/'+id, 
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const postTestRuns = async (accessToken: string, testSuiteId: number) =>{
    var response=await api().post(
        '/api/v1/testruns',
        {testSuiteId: testSuiteId},
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

