import { Socket, io } from "socket.io-client";
import { store } from "../store/store";
import { testRunCreated, testRunUpdated } from "../actions/testRunActions";
import { checkAuthExpired, signOut } from "../actions/authActions";

export default class SocketEventHandler{
  private static instance: SocketEventHandler;
  private socket?: Socket

  public static getInstance(): SocketEventHandler {
    if (!SocketEventHandler.instance) {
      SocketEventHandler.instance = new SocketEventHandler();
    }
    return SocketEventHandler.instance;
  }

  connect(authToken: string, orgId: number){
    console.log(`Socket connecting ${process.env.REACT_APP_SERVER_URL}${orgId}`)
    this.socket = io(`${process.env.REACT_APP_SERVER_URL}${orgId}`, {
      extraHeaders: {
        authorization: `Bearer ${authToken}`
      },
      auth: {
        token: `Bearer ${authToken}`
      }
    });

    this.socket.on('connect', () => {
      console.log('Socket connected ' + this.socket?.id);
    });

    this.socket.on('connect_error', (error) => {
      if (this.socket?.active) {
        console.log("Socket temporary failure, the socket will automatically try to reconnect");
      } else {
        console.log("Socket connection denied - "+error.message+" - signing out");
        store.dispatch(signOut())
      }
      this.disconnect()
    })

    this.socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    this.socket.on('test-run-created', (testRun: string) => {
      store.dispatch(testRunCreated(JSON.parse(testRun)));
    });

    this.socket.on('test-run-updated', (testRun: string) => {
      console.log("test-run-updated: "+testRun)
      store.dispatch(testRunUpdated(JSON.parse(testRun)));
    });
  }

  disconnect(){
    this.socket?.disconnect()
  }
}
