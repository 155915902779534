import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TestType } from '../models/testRun'
import { RootState } from './rootReducer'

export interface Error {
    message: string
}

export interface TestConfig{
    id?: number
    testType: TestType
    deviceId?: number
    cardId?: number
    timeoutDuration: number
    currency: string
    amount: number
    preCleanup: boolean
}

export enum SuiteState{
	Unknown = "Unknown",
    Manual = "Manual",
    Scheduled = "Scheduled",
}

export interface TestSuite{
    id?: number
    name: string
    scheduleFrequency?: number,
    favourite: boolean
    state: SuiteState,
    tests: TestConfig[]
}
export interface TestConfigPopulated{
    id: number
    testType: TestType
    device: {
        id: number
        username: string
    }
    card?: {
        id: number,
        name: string
    }
    timeoutDuration: number,
    preCleanup: boolean
}

export interface TestSuitePopulated{
    id: number
    name: string,
    scheduleFrequency: number,
    favourite: boolean,
    state: SuiteState,
    tests: TestConfigPopulated[]
}

export interface TestSuitesState{
    testSuites: TestSuitePopulated[]
    isLoading: boolean
    error: Error,
    page: number
    editing: {
        testSuite?: TestSuite,
        isEditing: boolean,
        isUpdating: boolean,
        error: Error
    }
}

export const initialState: TestSuitesState = {
    testSuites: [],
    isLoading: false,
    error: {message: ""},
    page: 1,
    editing: {
        isEditing: false,
        isUpdating: false,
        error: {message: ""}
    }
}

export const testSuitesSlice = createSlice({
    name: 'testSuites',
    initialState,
    reducers: {
        setReset: (state, {payload}: PayloadAction<void>) => {
            state.testSuites = []
        },
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setLoadSuccess: (state, { payload }: PayloadAction<{testSuites: TestSuitePopulated[], favourites: number[]}>) => {
            const favourites = payload.favourites
            state.testSuites = payload.testSuites.map(testSuite => ({...testSuite, favourite: favourites.includes(testSuite.id)}))
            state.error = {message: ""}
            state.isLoading = false
        },
        setLoadFailed: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.testSuites = []
            state.isLoading = false
        },
        setIsEditing: (state, { payload }: PayloadAction<boolean>) => {
            state.editing.isEditing = payload
            state.editing.isUpdating = false
            state.editing.error = {message: ""}
        },
        setEditingNewSuite: (state, { payload }: PayloadAction<void>) => {
            state.editing.isEditing = true
            state.editing.isUpdating = false
            state.editing.testSuite = {name: '', favourite: false, tests: [], state: SuiteState.Manual}
        },
        setCloneEditTestSuite: (state, { payload }: PayloadAction<TestSuite>) => {
            state.editing.isEditing = true
            state.editing.isUpdating = false
            state.editing.testSuite = payload
        },
        setSuiteToEdit: (state, { payload }: PayloadAction<TestSuite>) => {
            state.editing.testSuite = payload
        },
        setIsUpdating: (state, {payload}: PayloadAction<boolean>) => {
            state.editing.isUpdating = true
        },
        setUpdateFailed:  (state, { payload }: PayloadAction<Error>) => {
            state.editing.error = payload
            state.editing.isUpdating = false
        },
        setUpdateSuccess:  (state, { payload }: PayloadAction<TestSuite>) => {
            state.editing.isEditing = false
            state.editing.isUpdating = false
            state.editing.error = {message: ""}
        },
        setCurrentPage:  (state, { payload }: PayloadAction<number>) => {
            state.page = payload
        }
    },
})

export const selectFilteredTestSuites = (state: RootState) => {
    const favouritesOnly = state.settings.favouritesOnly
    return state.testSuites.testSuites.filter(testSuite => (testSuite.favourite && favouritesOnly) || !favouritesOnly)
}

export const { setLoadSuccess, setLoading, setLoadFailed, setIsEditing, setIsUpdating, setUpdateFailed, 
    setUpdateSuccess, setCurrentPage} = testSuitesSlice.actions
