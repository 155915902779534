import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Modal, Select, styled, TextField, Typography } from "@mui/material"
import { Device, Platform, Roles } from "../../reducers/devicesReducer";
import { User } from "../../reducers/usersReducer";
import { Account } from "../../reducers/authReducer";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { updateUser } from "../../actions/userActions";
import { updateAccount } from "../../actions/authActions";

const ContentStyle = styled(Box)({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 25,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    background: "#fff",
});

interface Props {
    user?: Account,
    isOpen: boolean,
    handleClose: () => void,
}

export const EditMyAccountModal = (props: Props) => {
    const [user, setUser] = useState(props.user)
    const dispatch = useAppDispatch()
    var isUpdating = useAppSelector(state => state.auth.editing.isUpdating)
    var error = useAppSelector(state => state.auth.editing.error)

    const handleSubmit = async() => {
        dispatch(updateAccount(user!))
    }

    return(
        <Modal
            open={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <ContentStyle>
                <Box component="form" noValidate sx={{ width: {sm:500} }}>
                    <Typography component="h1" variant="h5" sx={{mb: 3}}>
                        Update your account
                    </Typography>

                    <TextField autoFocus margin="dense" id="name" label="Username" InputProps={{readOnly: user?.id ? true : false}}
                        type="text" fullWidth variant="standard" value={user?.username}/>

                    <TextField autoFocus margin="dense" id="password" label="Password"
                        type="password" fullWidth helperText="Leave empty to not update" variant="standard" onChange={(e) => {setUser({...user!, ...{password: e.target.value}})}}/>

                    <Typography component="p" variant="body1" color="red" sx={{mt: 2}}>
                        {error.message}
                    </Typography>
                    
                    <LoadingButton variant="contained" loadingIndicator="Updating…" sx={{ mt: 3, mb: 2 }} loading={isUpdating} className="btn btn-primary" onClick={handleSubmit}>Update</LoadingButton>
                </Box>
            </ContentStyle> 
        </Modal>
    )
}
