import {Navigate} from "react-router-dom";
import { useAppSelector } from "../hooks/redux-hooks";

export type ProtectedRouteProps = {
  authenticationPath: string;
  outlet: JSX.Element;
};

const ProtectedRoute = ({authenticationPath, outlet}: ProtectedRouteProps) => {
  const isAuthenticated = useAppSelector(state => state.auth.isAuth)

  if(isAuthenticated) {
    return outlet;
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />;
  }
};

export default ProtectedRoute;