import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../reducers/rootReducer";
import { addFavouriteCard, deleteCard, deleteFavouriteCard, getCards, postCard, putCard } from "../api/cards";
import { Card, cardsSlice } from "../reducers/cardsReducer";
import { checkAuthExpired } from "./authActions";

export const cardActions = cardsSlice.actions

export const loadCards=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return getCards(getState().auth.accessToken || "", false).then( response => {
            dispatch(cardActions.setLoadSuccess({cards: response.cards, favourites: response.favourites}))
        }).catch(err => {
            dispatch(cardActions.setLoadFailed({message: "Failed to get cards:"+err}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const updateCard=(card: Card): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        dispatch(cardActions.setIsUpdating(true))
        return putCard(getState().auth.accessToken || "", card).then( response => {
            dispatch(cardActions.setUpdateSuccess(response))
            dispatch(loadCards())
        }).catch(err => {
            dispatch(cardActions.setUpdateFailed({message:err.response.data.error ?? err.message}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const createCard=(card: Card): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        dispatch(cardActions.setIsUpdating(true))
        return postCard(getState().auth.accessToken || "", card).then( response => {
            dispatch(cardActions.setUpdateSuccess(response))
            dispatch(loadCards())
        }).catch(err => {
            dispatch(cardActions.setUpdateFailed({message:err.response.data.error ?? err.message}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const removeCard=(id: number): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return deleteCard(getState().auth.accessToken || "", id).then( response => {
            dispatch(loadCards())
        }).catch(err => {
            dispatch(cardActions.setDeleteFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const addFavourite=(id: number): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return addFavouriteCard(getState().auth.accessToken || "", id).then( _ => {
            dispatch(loadCards())
        }).catch(err => {
            dispatch(cardActions.setDeleteFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const removeFavourite=(id: number): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, getState) => {
        return deleteFavouriteCard(getState().auth.accessToken || "", id).then( _ => {
            dispatch(loadCards())
        }).catch(err => {
            dispatch(cardActions.setDeleteFailed({message:err.response.data.error}))
            dispatch(checkAuthExpired(err))
        })
    }
}

export const cancelCardEditing=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        return dispatch(cardActions.setIsEditing(false))
    }
}

export const setCardEditing=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        return dispatch(cardActions.setIsEditing(true))
    }
}

export const clearError=(): ThunkAction<void, RootState, unknown, AnyAction> =>{
    return async(dispatch, _) => {
        return dispatch(cardActions.setClearError())
    }
}