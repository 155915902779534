import moment from 'moment';

export enum TestType{
	Unknown = "Unknown",
    ProvisionCard = "ProvisionCard",
    DeprovisionCard = "DeprovisionCard",
    ExecuteTransaction = "ExecuteTransaction",
    Delay = "Delay"
}

export enum TestState {
    NotStarted = "NotStarted",
    Pushed = "Pushed",
    Running = "Running",
    Complete = "Complete"
}

export interface ITestResultData{
    key: string
    value: string
}

export interface ITestResult{
    id?: number
    state: TestState
    testType: TestType
    device: string
    card: string
    timeoutDuration: number
    startTime: number
    endTime: number
    result: number
    duration: number | undefined
    startTimeStr: string
    endTimeStr: string
    startDateTimeStr: string
    endDateTimeStr: string
    data: ITestResultData[]
    dataStr: string,
    currency: string,
    amount: number,
    preCleanup: boolean
}

export interface ITestRun{
    id?: number
    name: string
    suite: string
    state: TestState
    startTime: number
    endTime: number
    expiryTime: number
    testResults: ITestResult[],
    startTimeStr: string,
    endTimeStr: string,
    result: number,
    resultStr: string
}

export interface ITestRunPage{
    page: number,
    perPage: number,
    total: number,
    testRuns: ITestRun[]
}

export class TestResult implements ITestResult{
    constructor(
        public id?: number,
        public state: TestState = TestState.NotStarted,
        public testType: TestType = TestType.Unknown,
        public device: string = "",
        public card: string = "",
        public timeoutDuration: number = 0,
        public startTime: number = 0,
        public endTime: number = 0,
        public result: number = 0,
        public amount: number = 0.0,
        public currency: string = "",
        public preCleanup: boolean = false,
        public data: ITestResultData[] = []
    ){}

    public get duration(): number | undefined{
        return this.endTime ? moment(this.endTime).valueOf() - moment(this.startTime).valueOf() : undefined
    }

    public get startTimeStr(): string{
        return this.startTime === 0 ? '-' : this.formatTime(this.startTime)
    }

    public get endTimeStr(): string{
        return this.endTime === 0 ? '-' : this.formatTime(this.endTime)
    }

    public get startDateTimeStr(): string{
        return this.startTime === 0 ? '-' : this.formatDateTime(this.startTime)
    }

    public get endDateTimeStr(): string{
        return this.endTime === 0 ? '-' : this.formatDateTime(this.endTime)
    }

    public get dataStr(): string{
        return this.data?.map(item => `${item.key}: ${item.value}`).join('\n')
    }
    
    private formatDateTime(dateTime: number): string{
        return (moment(dateTime)).format('YYYY-MMM-DD HH:mm:ss')
    }

    private formatTime(dateTime: number): string{
        return (moment(dateTime)).format('HH:mm:ss')
    }

}

export class TestRun implements ITestRun{
    constructor(
        public id?: number, 
        public name: string = "",
        public suite: string = "",
        public state: TestState = TestState.NotStarted,
        public startTime: number = 0,
        public endTime: number = 0,
        public expiryTime: number = 0,
        public testResults: ITestResult[] = []
    ){}


    public get result(): number{
        const didFailToCompleteTests = this.state === TestState.Complete && this.testResults.filter(res => res.state === TestState.Complete).length < this.testResults.length;
        if(didFailToCompleteTests){
            return 101
        }
        return this.testResults.filter(res => res.state === TestState.Complete).find(res => res.result !== 0)?.result || 0
    }

    public get resultStr(): string{
        switch(this.result){
            case 0: return "0 - Success"
            case 101: return "101 - Failed to complete"
            case 99: return "99 - Timeout"
            case 401: return "401 - Merchant API Unauthorised"
            case 400: return "400 - Merchant API Bad Request"
            default: return `${this.result} - Error`
        }
    }

    public get duration(): number | undefined{
        return this.endTime ? moment(this.endTime).valueOf() - moment(this.startTime).valueOf() : undefined
    }

    public get startTimeStr(): string{
        return this.formatDateTime(this.startTime)
    }

    public get endTimeStr(): string{
        return this.endTime ? this.formatDateTime(this.endTime) : '-'
    }

    private formatDateTime(dateTime: number): string{
        return (moment(dateTime)).format('YYYY-MMM-DD HH:mm:ss')
    }
}