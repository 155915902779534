import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './rootReducer'

export interface Error {
    message: string
}


export interface Card{
    id: number
    name: string,
    opc: string,
    favourite: boolean
}

export interface CardsState{
    cards: Card[]
    isLoading: boolean
    error: Error | undefined,
    editing: {
        isEditing: boolean,
        isUpdating: boolean,
        error: Error
    }
}

export const initialState: CardsState = {
    cards: [],
    isLoading: false,
    error: undefined,
    editing: {
        isEditing: false,
        isUpdating: false,
        error: {message: ""}
    }
}

export const cardsSlice = createSlice({
    name: 'cards',
    initialState,
    reducers: {
        setReset: (state, {}: PayloadAction<void>) => {
            state.cards = []
        },
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setClearError: (state) => {
            state.error = undefined
        },
        setLoadSuccess: (state, { payload }: PayloadAction<{cards: Card[], favourites: number[]}>) => {
            const sortedCards = payload.cards.sort((a,b) => a.name.localeCompare(b.name))
            const favourites = payload.favourites
            state.cards = sortedCards.map(card => ({...card, favourite: favourites.includes(card.id)}))
            state.error = undefined
            state.isLoading = false
        },
        setLoadFailed: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.cards = []
            state.isLoading = false
        },
        setDeleteFailed: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.isLoading = false
        },
        setIsUpdating: (state, {payload}: PayloadAction<boolean>) => {
            state.editing.isUpdating = true
        },
        setIsEditing: (state, { payload }: PayloadAction<boolean>) => {
            state.editing.isEditing = payload
            state.editing.isUpdating = false
        },
        setUpdateFailed:  (state, { payload }: PayloadAction<Error>) => {
            state.editing.error = payload
            state.editing.isUpdating = false
        },
        setUpdateSuccess:  (state, { payload }: PayloadAction<Card>) => {
            state.editing.isEditing = false
            state.editing.isUpdating = false
            state.editing.error = {message: ""}
        }
    },
})

export const selectFilteredCards = (state: RootState) => {
    const favouritesOnly = state.settings.favouritesOnly
    return state.cards.cards.filter(card => (card.favourite && favouritesOnly) || !favouritesOnly)
}

export const { setLoadSuccess, setLoading, setLoadFailed, setClearError, setDeleteFailed} = cardsSlice.actions
