import { TestSuite } from "../reducers/testSuitesReducer"
import api from "./api"

export const getTestSuites = async (accessToken: string) =>{
    var response=await api().get(
        '/api/v1/testsuites?populate=true', 
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const getTestSuite = async (accessToken: string, id: number) =>{
    var response=await api().get(
        '/api/v1/testsuites/'+id, 
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const putTestSuite = async (accessToken: string, testSuite: TestSuite) =>{
    var response=await api().put(
        '/api/v1/testsuites/'+testSuite.id,
        testSuite,
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const postTestSuite = async (accessToken: string, testSuite: TestSuite) =>{
    var response=await api().post(
        '/api/v1/testsuites',
        testSuite,
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const deleteTestSuite = async (accessToken: string, id: number) =>{
    var response=await api().delete(
        '/api/v1/testsuites/'+id,
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const addFavouriteTestSuite = async (accessToken: string, id: number) =>{
    var response=await api().post(
        '/api/v1/testsuites/'+id+'/favourite',
        {},
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const deleteFavouriteTestSuite = async (accessToken: string, id: number) =>{
    var response=await api().delete(
        '/api/v1/testsuites/'+id+'/favourite',
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}