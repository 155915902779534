import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import type {} from '@mui/x-data-grid/themeAugmentation';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff671b',
      contrastText: 'rgba(255,255,255,255)'
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "black"
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
          root: {
              '& .MuiDataGrid-cell:focus': {
                  outline: 'none'
              },
              '& .MuiDataGrid-cell:focus-within': {
                  outline: 'none'
              }
          },
      }
    }
  }
});

export default theme;