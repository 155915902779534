import { Device } from "../reducers/devicesReducer"
import api from "./api"

interface DeviceListResponse{
    devices: Device[],
    favourites: number[]
}

export const getDevices = async (accessToken: string) =>{
    var response=await api().get<DeviceListResponse>(
        '/api/v1/devices', 
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const postDevice = async (accessToken: string, device: Device) =>{
    var response=await api().post(
        '/api/v1/accounts',
        device,
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const putDevice = async (accessToken: string, device: Device) =>{
    var response=await api().put(
        '/api/v1/accounts/'+device.id,
        device,
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const getDeviceRefreshToken = async (accessToken: string, deviceId: number) =>{
    var response=await api().get(
        '/api/v1/devices/'+deviceId+'/refreshtoken',
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const deleteDevice = async (accessToken: string, id: number) =>{
    var response=await api().delete(
        '/api/v1/devices/'+id,
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const addFavouriteDevice = async (accessToken: string, id: number) =>{
    console.log('addFavouriteCard '+accessToken+" id:"+id)
    var response=await api().post(
        '/api/v1/devices/'+id+'/favourite',
        {},
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}

export const deleteFavouriteDevice = async (accessToken: string, id: number) =>{
    var response=await api().delete(
        '/api/v1/devices/'+id+'/favourite',
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer '+accessToken
          },
        }
    )
    return response.data
}