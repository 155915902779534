
import { useAppDispatch } from "../../store/store";
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { useAppSelector } from "../../hooks/redux-hooks";
import { AppLayout } from '../../components/layouts/AppLayout';
import { Component, useEffect, useState } from "react";
import { addFavourite, clearError, loadCards, removeCard, removeFavourite, setCardEditing } from "../../actions/cardActions";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Menu, MenuItem, Paper, Snackbar, Stack, Theme, Tooltip, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { Card, selectFilteredCards } from "../../reducers/cardsReducer";
import { EditCardModal } from "./editCardModal";
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import { grey, red, yellow } from "@mui/material/colors";
import { IPaginationState } from "../../models/paginationState";
import { FavouriteFilter } from "../../components/FavouriteFilter";
import { useSelector } from "react-redux";

const columns = (editCallback: (id: number) => void, deleteCallback: (id: number) => void, 
    addFavouriteCallback: (id: number) => void, removeFavouriteCallback: (id: number) => void) => {
    return [
        { field: 'id', headerName: 'Id', flex: 1},
        { field: 'name', headerName: 'Name', flex: 1},
        { field: 'opc', headerName: 'OPC', flex: 1},
        {
            field: 'actions', headerName: '', width: 110,
            renderCell: (params: GridRenderCellParams) => (
                <Stack sx={{height: '100%', alignItems: 'center'}} direction="row" spacing={1} useFlexGap={true}>
                    <Tooltip title="Edit the Card"><EditIcon onClick={() => {editCallback(params.row.id)}}/></Tooltip>
                    <Tooltip title="Delete the Card"><DeleteIcon sx={{ color: red[500] }}  onClick={() => {deleteCallback(params.row.id)}}/></Tooltip>
                    {params.row.favourite && 
                        <Tooltip title="Remove Favourite"><StarIcon sx={{ color: yellow[700] }}  onClick={() => {removeFavouriteCallback(params.row.id)}}/></Tooltip>                    
                    }                    
                    {!params.row.favourite && 
                        <Tooltip title="Favourite the Card"><StarIcon sx={{ color: grey[400] }}  onClick={() => {addFavouriteCallback(params.row.id)}}/></Tooltip>                    
                    }
                </Stack>
            )
        }
    ];
}

export const Cards = () =>  {
    const dispatch = useAppDispatch()
    const [editingCard, setEditingCard] = useState<Card | undefined>();
    
    var cards =useSelector(selectFilteredCards)

    useEffect(() => {
        dispatch(loadCards())}, []
    )

    const handleEditCard = (id: number) => {
        setEditingCard(cards.find(card => card.id === id))
        dispatch(setCardEditing())
    }

    const handleAddCard = () => {
        setEditingCard({} as Card)
        dispatch(setCardEditing())
    }

    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false)
    const [deleteCardId, setDeleteCardId] = useState<number>()

    const handleDeleteCard = (id: number) => {
        setDeleteCardId(id)
        setDeleteDialogIsOpen(true)
    }
    const handleDeleteCancel = () => {
        setDeleteDialogIsOpen(false)
    }

    const handleErrorClose = () => {
        dispatch(clearError())
    }

    const handleDelete = () => {
        dispatch(removeCard(deleteCardId!))
        setDeleteDialogIsOpen(false)
    }

    const [paginationModel, setPaginationModel] = useState<IPaginationState>({
        pageSize: 20,
        page: 0,
    });

    const handleAddFavourite = (id: number) => {
        dispatch(addFavourite(id))
    }

    const handleRemoveFavourite = (id: number) => {
        dispatch(removeFavourite(id))
    }

    var error = useAppSelector(state => state.cards.error)

    return(
        <AppLayout path="/cards">
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <Typography component="span" variant="h4" sx={{mb: 0}}>
                        Cards
                    </Typography>
                </Grid>
                <Grid item container xs={6} md={6} lg={6} justifyContent="flex-end" alignItems="center" >
                    <FavouriteFilter sx={{mr: 2}}></FavouriteFilter>
                    <Button variant="contained" sx={{ }} onClick = {handleAddCard} className="btn btn-primary">Add Card</Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '80vh'}}>
                        <DataGrid
                            rowHeight={50}
                            rows={cards}
                            columns={columns(handleEditCard, handleDeleteCard, handleAddFavourite, handleRemoveFavourite)}
                            paginationModel={paginationModel}
                            pageSizeOptions={[20]}
                            onPaginationModelChange={setPaginationModel}
                            disableRowSelectionOnClick={true}
                            sx={{
                                '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                                    marginTop: '1em',
                                    marginBottom: '1em'
                                }
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>
            
            <EditCardModal card={editingCard} handleUpdate={setEditingCard}/>

            <Snackbar
                open={error != undefined}
                autoHideDuration={3000}
                onClose={handleErrorClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                message={"Card not deleted - "+error?.message}
            />

            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                open={deleteDialogIsOpen}>
                <DialogTitle>Confirm</DialogTitle>
                <DialogContent dividers>
                    <Typography component="span" variant="body1" sx={{mb: 0}}>
                        Are you sure you want to delete the card?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDelete}>Ok</Button>
                </DialogActions>
            </Dialog>
        </AppLayout>
    )
}

