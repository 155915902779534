import { AppBar, Box, Container, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from "@mui/material"
import PeopleIcon from '@mui/icons-material/People';
import LogoutIcon from '@mui/icons-material/Logout';
import MonitorIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DevicesIcon from '@mui/icons-material/Devices';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAppDispatch } from "../../store/store";
import { cancelAccountEditing, setAccountEditing, signOut } from "../../actions/authActions";
import { NavigateOptions, useNavigate } from "react-router";
import mcLogo from "../../images/mc_logo.svg"
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import PasswordIcon from '@mui/icons-material/ManageAccounts';
import { useAppSelector } from "../../hooks/redux-hooks";
import { EditMyAccountModal } from "../../features/users/editMyAccountModal";
import BusinessIcon from '@mui/icons-material/Business';

const drawerWidth = 240;

interface Props {
    children: React.ReactNode,
    path: string
}

interface SidebarItem{
    text: string
    icon: any
    path: string
    isDivider?: boolean
}

const menuItems: SidebarItem[]  = [
    {text: 'Dashboard', icon: <MonitorIcon/>, path: "/dashboard"},
    {text: 'Test Suites', icon: <TableChartIcon/>, path: "/testsuites"},
    {text: 'Cards', icon: <CreditCardIcon/>, path: "/cards"},
    {text: 'Devices', icon: <DevicesIcon/>, path: "/devices"}
]

const adminMenuItems: SidebarItem[]  = [
    {text: 'Users', icon: <PeopleIcon/>, path: "/users"},
    {text: 'MDES', icon: <AccountBalanceIcon/>, path: "/mdes"}
]

const superadminMenuItems: SidebarItem[]  = [
    {text: 'Organizations', icon: <BusinessIcon/>, path: "/organizations"}
]

export const AppLayout = (props: Props) =>  {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    
    var userAccount = useAppSelector(state => state.auth.account)

    const handleSignout = () => {
        dispatch(signOut())
    }

    const isOpen = useAppSelector(state => state.auth.editing.isEditing)
    const handleUpdate = () => {
        handleMenuClose()
        dispatch(setAccountEditing())
    }
    
    const handleCloseEditAccount = () => {
        dispatch(cancelAccountEditing())
    }

    const handleItemClick = (path: string) => {
        navigate(path)
    }

    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const container = undefined

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const isMenuOpen = Boolean(anchorEl);
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
      };
    

    const accountRoles = useAppSelector(state => state.auth.account?.roles)
    const orgName = useAppSelector(state => state.auth.account?.organisationName)
    const menuId = 'primary-search-account-menu';

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{mt: 5}}
            open={isMenuOpen}
            onClose={handleMenuClose}>

            <MenuItem onClick={handleUpdate}>
                <ListItemIcon>
                    <PasswordIcon/>
                </ListItemIcon>
                Update account
            </MenuItem>
            <MenuItem onClick={handleSignout}>
                <ListItemIcon>
                    <LogoutIcon/>
                </ListItemIcon>
                Sign out
            </MenuItem>
        </Menu>
    )

    const drawerContents = (
        <div>
            <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        <ListItem disablePadding key='Org'>
                            <ListItemButton>
                                <ListItemText primary={orgName} primaryTypographyProps={{
                                    fontSize: 20,
                                    fontWeight: 'medium',
                                    letterSpacing: 0,
                                }}/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider/>
                    <List>
                        {menuItems.map((item, i) => {
                            return (
                                <ListItem disablePadding key={i}>
                                    <ListItemButton selected={item.path === props.path} onClick={ () => handleItemClick(item.path) }>
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.text} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                    <Divider/>
                    <List>
                        {(accountRoles?.includes('Admin') || accountRoles?.includes('SuperAdmin')) &&
                            adminMenuItems.map((item, i) => {
                                return (
                                    <ListItem disablePadding key={i}>
                                        <ListItemButton selected={item.path === props.path} onClick={ () => handleItemClick(item.path) }>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.text} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                    <Divider/>
                    <List>
                        {(accountRoles?.includes('SuperAdmin')) &&
                           superadminMenuItems.map((item, i) => {
                                return (
                                    <ListItem disablePadding key={i}>
                                        <ListItemButton selected={item.path === props.path} onClick={ () => handleItemClick(item.path) }>
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.text} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                    <Divider/>
                    <Typography sx={{pl: 2, mt: 20}} variant="caption">Build V{process.env.REACT_APP_VERSION}.{process.env.REACT_APP_GIT_COMIT_COUNT}</Typography>
                    <Divider/>
                </Box>
        </div>
      );

    return(
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img src={mcLogo} alt="logo" className="appbar-logo"/>
                    <Typography variant="h6" noWrap component="div">
                        MDES Monitor
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: {md: 'flex' } }}>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit">
                            <AccountCircle />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMenu}
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                container={container}
                    open={mobileOpen}
                    variant="temporary"
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{width: drawerWidth,flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}
                    >
                    {drawerContents}
                </Drawer>

                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                    >
                    {drawerContents}
                </Drawer>
            </Box>

            <Box sx={{ flexGrow: 1, p: 3, height: '100vh', overflow: 'auto', background: (theme) => theme.palette.grey[100]}}>
                <Toolbar />
                {props.children}
            </Box>
            <EditMyAccountModal user={userAccount} isOpen={isOpen} handleClose={handleCloseEditAccount}/>
        </Box>
    )
}