import './App.css';
import { SignIn } from './features/signIn/SignIn';
import {Route, Routes} from "react-router-dom";
import { Dashboard } from './features/dashboard/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import { TestSuites } from './features/testsuites/TestSuites';
import { Devices } from './features/devices/Devices';
import { Cards } from './features/cards/Cards';
import { EditTestSuite } from './features/testsuites/EditTestSuite';
import { Users } from './features/users/Users';
import SocketEventHandler from './service/socketEventHandler';
import { selectAccessToken, selectOrgId } from './reducers/authReducer';
import { store } from './store/store';
import { useEffect, useRef } from 'react';
import { Mdes } from './features/mdes/Mdes';
import { Organizations } from './features/organizations/organizations';

function App() {
  useEffect(() => {
    SocketEventHandler.getInstance().connect(selectAccessToken(store.getState())!, selectOrgId(store.getState())!)
  }, []);

  return (
    <Routes>
      <Route path="/" element={<SignIn/>}/>
      <Route path="/dashboard" element={<ProtectedRoute authenticationPath='/' outlet={<Dashboard/>} />} />
      <Route path="/testsuites" element={<ProtectedRoute authenticationPath='/' outlet={<TestSuites/>} />} />
      <Route path="/testsuites/edit" element={<ProtectedRoute authenticationPath='/' outlet={<EditTestSuite/>} />} />
      <Route path="/devices" element={<ProtectedRoute authenticationPath='/' outlet={<Devices/>} />} />
      <Route path="/cards" element={<ProtectedRoute authenticationPath='/' outlet={<Cards/>} />} />
      <Route path="/users" element={<ProtectedRoute authenticationPath='/' outlet={<Users/>} />} />
      <Route path="/mdes/:id" element={<ProtectedRoute authenticationPath='/' outlet={<Mdes/>} />} />
      <Route path="/mdes" element={<ProtectedRoute authenticationPath='/' outlet={<Mdes/>} />} />
      <Route path="/organizations" element={<ProtectedRoute authenticationPath='/' outlet={<Organizations/>} />} />
    </Routes>
  );
}

export default App;
