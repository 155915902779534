import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SettingsState {
    favouritesOnly: boolean;
}

export const initialState: SettingsState = {
    favouritesOnly: false
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setFavouritesOnly: (state, {payload}: PayloadAction<boolean>) => {
            state.favouritesOnly = payload
        }
    },
})

export const { setFavouritesOnly } = settingsSlice.actions
