import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TestRunAdapter } from '../adapters/testRunAdapter'
import { ITestRun, ITestRunPage } from '../models/testRun'
export interface Error {
    message: string
}

export interface TestRunState{
    page: number
    perPage: number
    total: number
    testRuns: ITestRun[]
    isLoading: boolean
    isPolling: boolean
    error: Error
}

export const initialState: TestRunState = {
    page: 0,
    perPage: 20,
    total: 0,
    testRuns: [],
    isLoading: false,
    isPolling: false,
    error: {message: ""}
}

const adapter = new TestRunAdapter()

export const testRunslice = createSlice({
    name: 'testRuns',
    initialState,
    reducers: {
        setReset: (state, {payload}: PayloadAction<void>) => {
            state.testRuns = []
        },
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.isLoading = payload
        },
        setLoadSuccess: (state, { payload }: PayloadAction<ITestRunPage>) => {
            state.page = payload.page
            state.total = payload.total
            state.testRuns = payload.testRuns.map(testRunDto => adapter.fromDto(testRunDto))
            state.error = {message: ""}
            state.isLoading = false
        },
        setLoadFailed: (state, { payload }: PayloadAction<Error>) => {
            state.error = payload
            state.testRuns = []
            state.isLoading = false
        },
        setIsPolling: (state, { payload }: PayloadAction<boolean>) => {
            state.isPolling = payload
        },
        setCurrentPage: (state, { payload }: PayloadAction<number>) => {
            state.page = payload
        },
        setAddTestRun: (state, { payload }: PayloadAction<ITestRun>) => {
            if(state.page == 0){
                state.testRuns.unshift(payload)
                state.testRuns = state.testRuns.slice(0, state.perPage)
            }
        },
        setTestRunUpdated: (state, { payload }: PayloadAction<ITestRun>) => {
            const updatedTestRunDto = adapter.fromDto(payload)
            state.testRuns = state.testRuns.map(testRunDto => testRunDto.id == updatedTestRunDto.id ? updatedTestRunDto : testRunDto)
        },
    },
})

export const { setLoadSuccess, setLoading, setLoadFailed, setTestRunUpdated, setAddTestRun} = testRunslice.actions
