import { Box, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { TestConfig } from '../../reducers/testSuitesReducer';
import { TestType } from '../../models/testRun';
import { Device } from '../../reducers/devicesReducer';
import { Card } from '../../reducers/cardsReducer';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
    test: TestConfig,
    devices: Device[],
    cards: Card[],
    handleTestChange: (test: TestConfig) => void
    handleDeleteTest: () => void
}

export const EditTest = ({test, devices, cards, handleTestChange, handleDeleteTest}: Props) => {
    return(
        <Box sx={{border: 1, borderColor: 'grey.400', borderRadius: 2, pl: 2, pr: 2, mb: 3, mt: 3}}>
            <Grid container sx={{mb: 2, align: 'center'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <EditTestType testType={test.testType} handleTestTypeChange={ (testType) => {handleTestChange({...test, testType: testType, deviceId: undefined, cardId: undefined})}}></EditTestType>
                    </Grid>

                    {test.testType && test.testType !== TestType.Delay &&
                        <Grid item xs={12} md={3}>
                            <EditTestDevice deviceId={test.deviceId} devices={devices} handleTestDeviceChange={ (deviceId) => {handleTestChange({...test, deviceId: deviceId})}}></EditTestDevice>
                        </Grid>
                    }

                    {[TestType.ProvisionCard, TestType.DeprovisionCard].includes(test.testType) &&
                        <Grid item xs={12} md={3}>
                            <EditTestCard cardId={test.cardId} cards={cards} handleTestCardChange={ (cardId) => {handleTestChange({...test, cardId: cardId})}}></EditTestCard>
                        </Grid>
                    }

                    {test.testType === TestType.ExecuteTransaction &&
                        <Grid item xs={12} md={3}>
                            <EditTestCurrency currency={test.currency} handleTestCurrencyChange={ (currency) => {handleTestChange({...test, currency: currency})}}></EditTestCurrency>
                        </Grid>
                    }

                    {test.testType === TestType.ExecuteTransaction &&
                        <Grid item xs={12} md={3}>
                                <TextField autoFocus margin="dense" id="amount" label="Transaction amount" 
                                    InputProps={{ inputProps: { min: 0, max: 10, step: '0.01'} }} InputLabelProps={{ shrink: (test?.amount !== null) }}
                                    type="number" fullWidth variant="standard" value={test?.amount} onChange={(e) => {handleTestChange({...test, amount: parseFloat(e.target.value)})}}/>
                        </Grid>
                    }
                </Grid>
            </Grid>
            

            <Grid container spacing={4} sx={{mb: 2, align: 'center'}}>
                <Grid item xs={5}>
                    <TextField autoFocus margin="dense" id="timeoutduration" label="Timeout Duration (seconds)"
                        type="number" fullWidth variant="standard" value={test?.timeoutDuration} onChange={(e) => {handleTestChange({...test, timeoutDuration: parseInt(e.target.value)})}}/>
                </Grid> 
                <Grid item xs={6} mt={3}>
                    {test.testType == TestType.ProvisionCard &&
                        <FormControlLabel
                            control={
                                <Checkbox onChange={(_, checked) => {handleTestChange({...test, preCleanup: checked})}} checked={test.preCleanup === true}/>
                            }
                            label="Clean-up before start" labelPlacement="end"
                        />
                    }
                </Grid>
                <Grid item container xs={1} mb={1} alignItems="end" justifyContent="flex-end">
                    <DeleteIcon sx={{}} onClick={() => {handleDeleteTest()}}/>
                </Grid>    
            </Grid>

        </Box>
    )
}

interface TestTypeProps {
    testType: TestType,
    handleTestTypeChange: (testType: TestType) => void
}

export const EditTestType = ({testType, handleTestTypeChange}:TestTypeProps) => {
    return(
        <FormControl sx={{ mt: 2, mb: 1 }} size="small" fullWidth>
            <InputLabel id="type-select-label">Test Type</InputLabel>
            <Select
                labelId="type-select-label"
                id="demo-simple-select"
                value={testType ? testType : ''}
                onChange={(e) => {handleTestTypeChange(e.target.value as TestType)}} 
                label="Test Type">
                <MenuItem value={TestType.ProvisionCard}>Provision Card</MenuItem>
                <MenuItem value={TestType.DeprovisionCard}>Deprovision Card</MenuItem>
                <MenuItem value={TestType.ExecuteTransaction}>Execute Transaction</MenuItem>
                <MenuItem value={TestType.Delay}>Delay</MenuItem>
            </Select>
        </FormControl>
    )
}

interface TestDeviceProps {
    deviceId?: number,
    devices: Device[],
    handleTestDeviceChange: (deviceId: number) => void
}

export const EditTestDevice = ({deviceId, devices, handleTestDeviceChange}:TestDeviceProps) => {
    return(
        <FormControl sx={{ mt: 2, mb: 1}} size="small" fullWidth>
            <InputLabel id="device-select-label">Device</InputLabel>
            {devices?.length > 0 &&
                <Select
                    name="device-select"
                    labelId="device-select-label"
                    id="device-select-label"
                    value={deviceId ? deviceId : ''}
                    size="small"
                    onChange={(e) => {handleTestDeviceChange(e.target.value as number)}}
                    label="Device">
                    {devices?.map(device =>
                        <MenuItem key={device.id} value={device.id}>{device.username}</MenuItem>
                    )}
                </Select>
            }
        </FormControl>
    )
}

interface TestCardProps {
    cardId?: number,
    cards: Card[],
    handleTestCardChange: (cardId: number) => void
}

export const EditTestCard = ({cardId, cards, handleTestCardChange}:TestCardProps) => {
    return(
        <FormControl sx={{ mt: 2, mb: 1 }} size="small" fullWidth>
            <InputLabel id="card-select-label">Card</InputLabel>
            {cards?.length > 0 &&
                <Select
                    labelId="card-select-label"
                    id="card-simple-select"
                    value={cardId ? cardId : ''}
                    size="small"
                    onChange={(e) => {handleTestCardChange(e.target.value as number)}}
                    label="Test Type">
                    {cards?.map(card => 
                        <MenuItem key={card.id} value={card.id}>{card.name}</MenuItem>
                    )}
                </Select>
            }
        </FormControl>
    )
}

interface TestCurrencyProps {
    currency: string,
    handleTestCurrencyChange: (currency: string) => void
}

export const EditTestCurrency = ({currency, handleTestCurrencyChange}:TestCurrencyProps) => {
    return(
        <FormControl sx={{ mt: 2, mb: 1}} size="small" fullWidth>
            <InputLabel id="type-select-label">Currency</InputLabel>
            <Select
                labelId="type-select-label"
                id="demo-simple-select"
                value={currency}
                defaultValue={"USD"}
                onChange={(e) => {handleTestCurrencyChange(e.target.value as string)}}//handleCurrencyChange(index, e.target.value as string)
                label="Test Type">
                <MenuItem key={'USD'} value="USD">USD</MenuItem>
                <MenuItem key={'GBP'} value="GBP">GBP</MenuItem>
                <MenuItem key={'EUR'} value="EUR">EUR</MenuItem>
            </Select>
        </FormControl>
    )
}