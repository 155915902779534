
import { useAppDispatch } from "../../store/store";
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { useAppSelector } from "../../hooks/redux-hooks";
import { AppLayout } from '../../components/layouts/AppLayout';
import { useEffect, useState } from "react";
import { cancelCardEditing, createCard, loadCards, removeCard, setCardEditing, updateCard } from "../../actions/cardActions";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { loadTokenStatusHistory } from "../../actions/mdesActions";
import { useParams } from "react-router";


export const Mdes = () =>  {
    const dispatch = useAppDispatch()
    const { id } = useParams()
    const [tokenId, setTokenId] = useState(id ?? "")

    useEffect(() => {
        if(tokenId !=  ""){
            handleSubmit()
        }
    }, [tokenId])
    
    var error = useAppSelector(state => state.mdes.error)
    var isLoading = useAppSelector(state => state.mdes.isLoading)
    var statusHistory = useAppSelector(state => state.mdes.tokenStatusHistory)
    var statusTransactions = useAppSelector(state => state.mdes.tokenTransactions)
    var statusTokenId = useAppSelector(state => state.mdes.tokenId)

    const handleSubmit = async() => {
        dispatch(loadTokenStatusHistory(tokenId))
    }

    return(
        <AppLayout path="/mdes">
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <Typography component="span" variant="h4" sx={{mb: 0}}>
                        MDES Diagnostics
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            }}>
                        <Box component="form" noValidate sx={{overflowX: 'hidden'}}>
                            <Typography component="h1" variant="h5" sx={{mb: 1}}>
                                Enter the Token Unique Reference (TUR)
                            </Typography>

                            <TextField autoFocus margin="dense" id="name" label="Token Unique Reference" placeholder="e.g. DSHRMC00002426668343aafbd2f54da6b117c63e6bd55a6d" value={tokenId}
                                onChange={(e) => {setTokenId(e.target.value)}} type="text" fullWidth variant="standard"/>

                            <Typography component="p" variant="body1" color="red">
                                {error.message}
                            </Typography>
                            
                            <LoadingButton variant="contained" loadingIndicator="Retrieving…" sx={{ mt: 3, mb: 5 }} loading={isLoading} className="btn btn-primary" onClick={handleSubmit}>Retreive Token Info</LoadingButton>

                            { !isLoading &&
                                <Box>
                                    <Typography component="h1" variant="h5" sx={{mb: 1}}>
                                        {statusTokenId}
                                    </Typography>

                                    <Typography component="h1" variant="h6" sx={{mb: 1}}>
                                        Token Status History
                                    </Typography>

                                    <Table sx={{ }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Initiator</TableCell>
                                                <TableCell>Comment Id</TableCell>
                                                <TableCell>Reason Code</TableCell>
                                                <TableCell>Status Code</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell>Date/Time</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {statusHistory.map((row) => (
                                            <TableRow
                                                key={row.StatusDateTime}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">{row.Initiator}</TableCell>
                                                <TableCell component="th" scope="row">{row.CommentId}</TableCell>
                                                <TableCell component="th" scope="row">{row.ReasonCode}</TableCell>
                                                <TableCell component="th" scope="row">{row.StatusCode}</TableCell>
                                                <TableCell component="th" scope="row">{row.StatusDescription}</TableCell>
                                                <TableCell component="th" scope="row">{row.StatusDateTime}</TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>

                                    <Typography component="h1" variant="h6" sx={{mb: 1, mt: 4}}>
                                        Token Transactions
                                    </Typography>

                                    <Table sx={{}} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Currency</TableCell>
                                                <TableCell>Amount</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Merchant</TableCell>
                                                <TableCell>Category</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell>POS Entry Mode</TableCell>
                                                <TableCell>Date/Time</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {statusTransactions.map((row) => (
                                            <TableRow
                                                key={row.TransactionDateTime}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">{row.CurrencyCode}</TableCell>
                                                <TableCell component="th" scope="row">{row.TransactionAmount}</TableCell>
                                                <TableCell component="th" scope="row">{row.TransactionTypeCode}</TableCell>
                                                <TableCell component="th" scope="row">{row.TransactionTypeDescription}</TableCell>
                                                <TableCell component="th" scope="row">{row.TransactionStatusCode}</TableCell>
                                                <TableCell component="th" scope="row">{row.MerchantName}</TableCell>
                                                <TableCell component="th" scope="row">{row.MerchantCategoryCode}</TableCell>
                                                <TableCell component="th" scope="row">{row.MerchantCategoryDescription}</TableCell>
                                                <TableCell component="th" scope="row">{row.POSEntryMode}</TableCell>
                                                <TableCell component="th" scope="row">{row.TransactionDateTime}</TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            }
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

        </AppLayout>
    )
}
