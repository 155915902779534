import api from "./api"

export const createSession = async (username: string, password: string) =>{
    var response=await api().post(
        '/api/v1/session', 
        {username: username, password: password},
        {headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
    )
    return response.data
}

